@import "~bulma/sass/utilities/_all.sass";

.call-container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.call-container.no-side-menu {
  // grid-template-columns: 1fr;
  // grid-template-areas: "content";
}

.main-content {
  flex-grow: 1;
  position: relative;
  background-color: #2b2b2b;
  width: calc(100% - 300px);
}

.call {
  position: relative; /* To make it a "positioned" container so children layout works */
  height: calc(100% - 60px); /* Space for the tray */
  max-height: calc(100% - 60px);
}

#tile-layout {
  height: 100%;

  &.with-small-tiles {
    height: calc(100% - 132.5px);
  }
  &.with-circled-tiles {
    height: calc(100% - clamp(126px, 18vh, 270px));
  }
}

.large-tiles {
  height: 100%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(30%, 1fr));
  align-items: center;
  // overflow-y: overlay;
  flex-wrap: wrap;
  z-index: 0;
  padding: 1px;
  padding-bottom: 0;
  gap: 1px;
  overflow: hidden !important;
}

.tile {
  flex-grow: 1;
  min-height: 0;
}

.tile-for-2.vertical {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  .tile.large:not(.screenshare):not(.resizing) video {
    object-fit: contain;
  }
}

.tile-for-2.superwide {
  video {
    width: 100%;
  }
}

.tile-for-2.vertical-3 {
  video {
    object-fit: cover !important;
  }
}

.tile-for-3 {
  &.vertical-3 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  &.superwide {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

.tile-for-4:not(.superwide) {
  grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(49%, 1fr));
}

.tile-for-4.superwide {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
  grid-template-rows: 1fr !important;
}

.tile-for-3:not(.vertical-3):not(.superwide) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  .tile:nth-child(1),
  .tile:nth-child(2) {
    grid-column-end: span 2;
  }
  .tile:nth-child(3) {
    grid-column-start: 2;
    grid-column-end: 4;
    justify-content: center;
  }
}

.tile-for-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  .tile:nth-child(1),
  .tile:nth-child(2),
  .tile:nth-child(3) {
    grid-column-end: span 2;
  }
  .tile:nth-child(4),
  .tile:nth-child(5) {
    grid-column-start: span 3;
  }

  .tile:nth-child(4) {
    // justify-self: end;
  }
  .tile:nth-child(5) {
    // justify-self: start;
  }
}
.tile-for-3 .tile {
  // min-width: 49%;
  // max-width: 60%;
  // max-height: 50%;
}

// .tile-for-5 .tile {
//   min-width: 30%;
//   max-height: 50%;
// }

// DESKTOP SAFARI ONLY STYLES
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    @supports not (-webkit-touch-callout: none) {
      .tile-for-2.vertical,
      .tile-for-3.vertical {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: initial;
        max-height: 100%;
        .tile:not(:first-child) {
          margin-top: 1px;
        }
      }
    }
  }
}

.tile-for-6 {
  grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(50%, 1fr));
}

.tile-for-16 {
  grid-template-columns: repeat(auto-fit, minmax(24%, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(24%, 1fr));
}

.tile-for-25 {
  grid-template-columns: repeat(auto-fit, minmax(19%, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(19%, 1fr));
}

.tile-for-36 {
  grid-template-columns: repeat(auto-fit, minmax(16%, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(16%, 1fr));
}

.tile-for-49 {
  grid-template-columns: repeat(auto-fit, minmax(14%, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(14%, 1fr));
}

.tile-for-64 {
  grid-template-columns: repeat(auto-fit, minmax(11%, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(11%, 1fr));
}

.tile-for-81 {
  grid-template-columns: repeat(auto-fit, minmax(10%, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(10%, 1fr));
}
.tile-for-100 {
  grid-template-columns: repeat(auto-fit, minmax(9%, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(9%, 1fr));
}

.small-tiles-scroller {
  width: 100%;
  overflow-x: auto;
  position: relative;
  height: 132.5px;
  overflow-x: auto;
  overflow-y: hidden;

  .small-tiles-scroll-right {
    position: absolute;
    height: 100%;
    width: 20px;
    background-color: red;
    right: 0;
    top: 0;
  }

  .small-tiles-scroll-left {
    position: absolute;
    height: 100%;
    width: 20px;
    background-color: red;
    left: 0;
    top: 0;
  }
}

.small-tiles {
  height: 132.5px; /* Video height + 10px padding either side */
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: auto;
}

.call-room {
  flex-grow: 1;
  display: flex;
  height: 100%;
  overflow-y: auto;

  &__stage {
    width: 100%;
    height: 100%;
    position: sticky;
    top: 0;
  }

  &.with-cluster-list {
    &__stage {
      width: calc(100% - 200px);
    }
  }
}
