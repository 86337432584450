.settings-in-call-modal {
  h1 {
    font-weight: bold;
  }

  .input-section:not(:last-child) {
    padding-bottom: 1rem;
  }

  .input-section:last-child {
    padding-bottom: 0.5rem;
  }

  .Dropdown-arrow {
    top: 18px;
  }
}

.modal-content {
  overflow: visible;
}
