.Toastify {
  .Toastify__toast {
    border-radius: 0.5rem;
    padding: 3rem;
    padding-bottom: 3rem;

    .Toastify__toast-body {
      color: #656565;

      h1 {
        font-size: 2rem;
        margin-bottom: 0.5rem;
      }
    }

    .Toastify__close-button--default {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    .Toastify__progress-bar--default {
      background: linear-gradient(
        315deg,
        rgba(0, 116, 224, 1) 0%,
        rgba(67, 154, 255, 1) 100%
      );
    }
  }
}
