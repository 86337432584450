.start-button {
  font-size: 16px;
  font-weight: 600;
  background-color: #EC181C;
  color: white;
  display: inline-block;
  border-radius: 6px;
  padding: 15px 20px;
  border: none;
  cursor: pointer;
}

.start-button:hover {
  background-color: #E20E19;
}

.start-button svg {
  width: 24px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-bottom;
}
