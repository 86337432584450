@import "../../styles/colors.scss";

.office-side-menu-content {
  padding-top: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  flex-grow: 1;
  overflow-y: auto;
  height: calc(100vh - 48px);

  .office-side- padding {
    padding-bottom: 1.5rem;
  }

  .menu-section {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  .section-header {
    display: flex;
    align-items: center;
    height: 1.2rem;
    margin-bottom: 0.33rem;

    > .header-title {
      flex-grow: 1;

      > svg {
        margin-right: 0.25em;
      }
    }

    > .header-right {
      flex-grow: 0;
      .button {
        color: #757575;
        span {
          width: 2em;
        }
      }
    }

    .header-title {
      text-transform: uppercase;
      font-size: 0.85rem;
      color: #757575;
      font-weight: 700;
    }
  }

  .update-available {
    font-size: 0.9rem;
    margin-bottom: -1.5rem;
    padding: 0.6rem 1rem;

    .button {
      font-size: 0.9rem;
      margin-left: 1rem;
    }
  }

  .offline-alert {
    font-size: 0.9rem;
    margin-bottom: -1.5rem;
    padding: 0.6rem 1rem;

    h2 {
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.33rem;
      }
    }
  }

  .manage-spaces-menu {
    padding: 0;
    li {
      a {
        padding: 0.5rem 0.75rem;
        line-height: normal;
      }
    }
  }

  .meetings-list {
    .new-meeting-icon {
      font-size: 1.0667rem;
    }

    .more-meetings-toggle {
      color: #969696;
      cursor: pointer;
      border-radius: 0.25rem;
      padding: 0.25rem 0.25rem;

      &:hover {
        color: #333;
        background-color: #f5f5f5;
      }

      svg {
        width: 1rem;
      }
    }

    .no-meetings-placeholder {
      color: #969696;
    }
  }

  .spaces-list {
    margin-bottom: 0.5rem;

    h1 {
      margin-bottom: 10px;
    }

    .menu-list {
      line-height: 0;
    }

    .menu-list > li.space-box {
      @extend .space-outline;
      cursor: pointer;
      display: flex;
      &:hover {
        background-color: #efefef;
      }
    }

    .add-space-icon {
      font-size: 0.93333rem;
    }
  }

  .people-list {
    .add-user-icon {
      font-size: 0.8rem;
      opacity: 0.9;
    }

    .people-row {
      padding-right: 0.33rem;
    }
  }

  .office-rooms-menu {
    font-size: 1rem;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0.25rem;
    .join-room-button {
      align-self: flex-start;
    }

    &:not(.in-call) {
      .space-name-header {
        border: none;
      }
      .space-rooms-list {
        border-left: none;
        border-right: none;
        border-bottom: none;
        // border-bottom-width: 2px;
      }
      .space-in-call-actions {
        display: none;
      }
    }

    &.in-call {
      .room-row-container__current {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        border-radius: 4px;
        background-color: $here-background;
        padding-left: 2rem !important;
        padding-right: 1.25rem !important;

        .react-toggle-track {
          background-color: #c2d2ef;
        }
        .react-toggle--checked .react-toggle-track {
          background-color: #c2b7f6;
        }

        .participant-nametags-list {
          .participant-name-tag {
            justify-content: flex-start;
          }

          .participant-name-tag:hover {
            background-color: $here-tag-hover !important;
            cursor: pointer;

            .you-label {
              // background-color: #f0f0f0;
            }
          }

          .name-text {
            max-width: 12rem;
          }
        }
      }
      .participant-nametags-list {
        .participant-name-tag:hover {
          background-color: #eff4fe !important;
          cursor: pointer;
        }
      }
      .space-in-call-actions {
      }
    }
    .space-link {
      display: inline-block;
      height: 1.2rem;

      .icon {
        max-height: 1.2rem;
        line-height: 1.2rem;
        cursor: pointer;
        margin-left: 1px;
        margin-right: 0.11rem;
        border-radius: 4px;
        vertical-align: -1px;
      }
      .icon:hover {
        background-color: rgb(249, 249, 249);
      }
      svg {
        transform: rotate(44deg);
        font-size: 0.8rem;
        color: $grey;
      }
    }

    &.in-a-call {
      .space-name-header {
        &:hover {
          .space-arrow {
            display: none !important;
          }
          .down-arrow {
            // color: #777;
            display: flex !important;
          }
        }
        &.is-expanded {
          &:hover {
            .down-arrow {
              display: none !important;
            }
            .up-arrow {
              display: flex !important;
            }
          }
          .down-arrow {
            display: flex !important;
          }
        }
      }
    }

    .space-name-header {
      @extend .space-name-title;
      @extend .space-outline;
      margin-bottom: 0 !important;
      cursor: pointer;
      display: flex;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;

      &.in-call:not(.collapsed-single-room) {
        padding: 0.75rem 0.5rem 0.75rem 0.5rem !important;
      }

      &.in-call.collapsed-single-room {
        padding: 0.75rem 0.5rem 0 0.5rem !important;
      }

      &:not(.in-call) {
        border: none;
      }

      &:not(.in-call) {
        &:hover,
        &.in-right-pane {
          background-color: $spaceOutline;
          .space-arrow {
            display: block;
          }
        }
      }

      &.in-call {
        font-weight: bold;
        cursor: inherit;

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.with-content {
        border-bottom: none;
      }

      &.is-expanded {
        background-color: $spaceOutline;
      }

      .space-name {
        flex-grow: 1;
        display: flex;
        align-items: baseline;
        line-height: 18px; //FIX

        .space-icon svg {
          font-size: 0.625rem;
          margin-right: 0.33rem;
          vertical-align: 0.05em;
        }

        .space-icon.calendar-icon {
          svg {
            font-size: 0.75rem;
          }
        }
      }
      .space-name-arrows {
        width: 1rem;
      }

      .space-arrow,
      .down-arrow,
      .up-arrow {
        flex-grow: 0;
        display: none;
        align-items: center;
        font-size: 0.75rem;
      }
    }

    > .space-rooms-list {
      border-top: none;
      padding-top: 1px;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    ul.rooms-list {
      > li {
        margin-top: 0;
        margin-bottom: 0;
      }

      .room-row-container {
        padding: 0.5rem 0.5rem 0.4rem 1.5rem;
        margin-bottom: 0.75rem;
      }

      .here-indicator {
        color: #555555;
        line-height: 22.5px;
        margin-top: 0;
      }

      .add-room-button-row {
        margin-bottom: 1rem;
        margin-left: 1.5rem;
        margin-top: 1rem;
      }

      .join-room-button {
        font-size: 0.7333333rem;
      }
    }

    &.unsubscribed {
    }

    .participant-name-tag .nametag-info {
      position: relative;
      right: inherit;
      top: inherit;
      transform: none;
      margin-left: 0.5rem;
    }
  }

  .lobby-name-row {
    margin-bottom: 0.25rem;

    > .level {
    }
    .level-right {
      .level-item {
        // margin-top: 2px;
      }
    }
  }

  .lobby-name-row .room-name {
    font-size: 1em;
    // vertical-align: middle;
    width: inherit;

    &:hover {
      .floating-actions {
        background-color: white !important;
      }
    }

    .floating-actions {
      background-color: transparent !important;
    }
  }

  .add-room-button {
    margin-right: 0.5rem;
  }

  .room-row-container__current {
    background-color: #f5f5f5;
    .participant-name-tag:hover {
      background-color: white !important;
    }
  }

  .other-spaces-list {
    margin-bottom: 1rem;

    > ul {
      > li {
        font-size: 1rem;
        margin-bottom: 0.125rem;
      }

      .in-right-pane {
        // color: #555;
      }
    }

    .space-name-header {
      padding: 0.4rem 0.5rem !important;

      &:hover {
        background-color: #f3f3f3 !important;
        // color: #555;
      }
    }
  }

  .other-spaces-section {
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
    justify-content: space-between;

    a {
      color: #757575;
      &:hover {
        .link-text {
          text-decoration: underline;
        }
      }

      svg {
        width: 0.7em;
        margin-right: 0;
      }
    }

    svg {
      margin-right: 0.25em;
    }
  }

  .room-row-container {
    .locked-icon {
      display: flex;
      margin-top: 2px;
    }

    .lock-toggle-container {
      display: flex;
      align-items: center;
      height: 22px;
    }
    .locked-toggle {
    }
  }

  .new-offscreen-messages {
    display: none;
    position: absolute;
    left: 6rem;
    background: #00d182;
    border-radius: 20px;
    padding: 3px 12px;
    cursor: pointer;
    font-weight: 500;
    color: white;
    // box-shadow: 8px 16px 16px hsla(0deg, 0%, 0%, 0.25);
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.15);

    &.top {
      top: 3.5rem;
    }

    &.bottom {
      bottom: 1rem;
    }

    svg {
      font-size: 0.7rem;
      margin-right: 0.4rem;
      margin-top: 0.4rem;
    }

    &.notify {
      display: flex;
      justify-content: center;
    }
  }

  .session-settings {
    margin-left: 0.5rem;
  }

  .in-row-meeting-link {
    font-size: 12px;
    margin-left: 0.5rem;
  }

  .calendar-icon {
    color: #848484;
  }

  .new-meeting-tippy {
    padding: 0.5rem;
  }

  .new-meeting-content {
    h1 {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 0.75rem;
    }
    .meeting-name-input {
      min-width: 17rem;
    }
    .input,
    .button {
      font-size: 0.8rem;
    }

    .input-attached-button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .editing-meeting {
    background-color: #f5f5f5;
    border-radius: 4px;
  }

  .meeting-header {
    padding: 0.5rem 3px 0.5rem 0.5rem;
    margin-bottom: 0;

    &:hover {
      background-color: $spaceOutline;
      border-radius: 0.3125rem;
      cursor: pointer;
    }

    .level-left {
      height: 18px;
    }
  }

  .in-row-meeting-link {
    svg {
      transform: rotate(44deg);
      font-size: 0.8rem;
      color: $grey;
    }
  }

  .user-meeting-row {
    margin-bottom: 0.25rem;

    .space-name-header {
    }
    .space-rooms-list {
      &.collapsed-single-room {
        ul.rooms-list {
          .room-row-container {
            margin-top: -0.25rem;
            padding-top: 0 !important;
          }
        }
      }
    }

    .space-outline {
      border: none;
    }
  }
}

.conversation-section {
  margin-bottom: 1em;

  .conversation-participants {
    padding: 0.15em 0.4em;

    .participant-name-tag {
      &:hover {
        .actions {
          display: none;
        }
      }
    }

    .add-room-button-row {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;

      .add-room-button {
        .is-outlined {
          font-size: 0.6rem;
        }
      }
    }
  }

  .office-rooms-menu {
    .space-rooms-list {
      .rooms-list {
        .room-row-container {
          padding-left: 1rem;

          &:first-child {
            margin-top: 0.33rem;
          }
        }

        .add-room-button-row {
          margin-left: 1rem;
        }
      }
    }
  }
}

.user-participant-actions {
  display: flex;
  align-items: center;
  height: 28px;

  .direct-call-button {
    color: $blue;
    width: 1.8666666rem;
    height: 1.8666666rem;
    font-size: 0.9rem;
    padding: 0;

    &:hover {
      color: $blue;
    }
    &.ringing {
      svg {
        color: $lgreen;
        animation: ring 2s ease-in-out infinite;
      }
    }
  }

  .wave-button {
    height: 1.8666666rem;
    width: 1.8666666rem;
    padding: 0;
  }

  .hang-up-button {
    height: 1.8666666rem;
    width: 1.8666666rem;
    font-size: 0.9rem;
    padding: 0;
  }

  .join-room-button {
    width: 54px;

    &.knocking-button {
      padding-left: 8px;
      padding-right: 8px;
      width: inherit;
      margin-left: 1px;
      border: none;
      opacity: 0.6;
    }

    &.join-room-button--joining {
      width: inherit;
      opacity: 0.6;
      cursor: progress;
      pointer-events: none;
    }
  }

  .here-indicator {
    margin-left: 0.75rem;
  }

  .knock-cancel-button {
    color: inherit;
    color: #aaa;
    font-size: 0.8rem;
    margin-right: -0.4rem;

    &:hover {
      color: inherit;
      color: #888;
      background-color: transparent !important;
    }
  }

  .private-call-button {
    height: 1.9em;
    margin-left: 0.25rem;

    .icon {
      font-size: 0.6rem;
    }

    &.ringing {
      .icon {
        font-size: 0.75rem;
      }
    }
  }

  .in-user-chat {
    font-size: 0.75rem;
    height: 1.9em;
    margin-right: 0.3rem;
  }
}

.space-name {
  // font-size: 1.0666667rem;
}

.space-name-text {
  padding-right: 0.5rem;
}

.space-name-title {
  // font-weight: bold;
  line-height: normal;
}

.space-outline {
  // padding: 0.75rem 0.75rem 0.75rem 1rem;
  align-items: center;
  border: 3px solid $spaceOutline;
  border-radius: 0.3125rem;
  margin-bottom: 0.5rem;

  .space-name-box {
    @extend .space-name-title;
  }

  &--closed-top {
    border-top: 3px solid $spaceOutline !important;
  }
}

.tiny-rounded-profile-photo {
  border-radius: 0.33rem;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
}

.tiny-profile-photo-container {
  display: inline-flex;
  margin-right: 0.5em;
}

.tiny-placeholder-profile-photo {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.photo-with-activity {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  .activity-dot {
    position: absolute;
    top: -0.275rem;
    right: -0.05rem;
  }

  .tiny-profile-photo-container {
    margin-right: 2px;
  }

  .css-activity-dot {
    position: absolute;
    top: -0.3rem;
    right: -4px;
    width: 14px;
    height: 14px;
    border-radius: 0.5em;
    border: 3px solid white;

    &.activity--online {
      background-color: $sbcarribean;
    }

    &.activity--offline {
      background-color: #c9c9c9;
    }

    &.activity--do-not-disturb {
      background-color: $sbred;
    }
    &.activity--door-is-open {
      background-color: $sbroyalblue;
    }
  }
}

.participant-nametags-list.non-inline-list {
  margin-top: 0.25rem;

  .participant-name-tag {
    display: flex;
    margin-bottom: 1px;
    background-color: transparent;
    border-radius: 0.25rem !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0.25em;
    padding-right: 0.25em !important;
    margin: 0;
    height: 30px !important;

    &.currently-focused-tag {
      background-color: #eff4f4 !important;
    }

    &.is-lightblue,
    &.is-lighter-blue {
      background-color: transparent !important;
    }

    &.audio-only-padding {
      box-shadow: none;
    }

    &:hover {
      background-color: $here-tag-hover !important;
      cursor: pointer;
      .actions {
        visibility: visible;
      }
    }

    &.no-actions:hover {
      background-color: inherit !important;
      cursor: default;
    }

    &.participant-me {
      .you-label {
        background-color: #c7d9fc;
        margin-left: 0;
        // display: inline;
        // height: 24px;
        // font-weight: 500;
        // padding: 0;
        // line-height: 27px;
        // font-size: 0.75rem;
        // margin-right: 0.5rem;
      }

      .name-text {
        max-width: 182px;
      }
    }

    .name-text {
      font-size: 0.875rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline;
      height: 24px !important;
      line-height: 24px;
      vertical-align: middle;
      overflow: hidden;
      align-items: center;
      margin-right: 0;
      max-width: 10.666667rem;
    }

    .actions {
      visibility: hidden;
      display: inline-block;
      vertical-align: middle;
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;

      .join-me-button {
        font-size: 0.75rem;
        padding: 0 0.75em;
        height: 2em;
      }
      .button.is-white {
      }
    }

    .nametag-info {
      .icon {
        display: none;
      }
    }

    .photo-wrapper {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }

    .tiny-rounded-profile-photo {
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
    }
  }
}

.other-spaces-activity-count {
  padding: 0.125rem 0.5rem;
  margin-right: 0.625rem;
  background-color: #00d182;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.125rem;
  width: 1.438rem;
  border-radius: 0.75rem;
  font-weight: 600;
  font-size: 0.75rem;
  color: #ffffff;
}

.with-activity {
  font-weight: 700;
}
.admin-member-onboarding {
  margin-bottom: -0.5rem;

  .reminder-item,
  .reminder-item:hover,
  .reminder-item:active,
  .reminder-item:focus {
    justify-content: flex-start;
    color: $yet-another-blue;
    font-weight: 500;
    text-align: left;
    position: relative;

    .icon-wrapper {
      width: 1.75rem;
      text-align: left;
    }

    .fa-slack {
      margin-left: 1px;
      font-size: 17px;
    }

    .dismiss-reminder {
      position: absolute;
      right: 0.75rem;
      top: 0.425rem;
      font-size: 1.133rem;
      opacity: 0.6;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-right: -0.5rem;
      &:hover {
        opacity: 1;
      }
    }
  }

  .reminder-item:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
