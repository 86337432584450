.extra-menu__footer {
  padding: 16px 4px 4px 4px;
  display: flex;
  justify-content: center;
}

.extra-menu__box {
  color: #4a4a4a;
  display: block;
  padding: 10px 6px;
}

.cancel-button {
  color: #444;
  text-transform: uppercase;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 16px;
  margin-right: 8px;
  cursor: pointer;

  &:hover {
    color: #444;
    border-color: #ddd;
  }
}

.extra-menu__title {
  font-size: 1.5rem;
  margin-bottom: 3px;
  font-weight: 600;
}
