.ReactModal__Overlay__disabled {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(10, 10, 10, 0.5) !important;
}

.ReactModal__Content__notification {
  outline: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 900px;

  &.ReactModal__Content__corner {
    right: 80px;
    bottom: 100px;
    transform: none;
    top: unset;
    left: unset;
    max-width: 700px;
  }

  &.ReactModal__Content {
    margin-top: 0;
  }

  .modal-header {
    color: #2d2d2d;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }

  .modal-content {
    color: #818181;
    font-weight: 600;
    font-size: 15px !important;
    margin-bottom: 20px;
    width: 100%;
    text-align: justify;

    a:not(.button) {
      color: #00d1b2;
      font-weight: bold;
    }
  }

  .modal-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;

    &__cancel {
      color: #00d1b2;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
    }

    &__troubleshoot {
      color: #00d1b2;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      margin-left: 25px;
    }
  }

  .chrome-mac-instructions {
    width: 460px;
    height: 390px;
  }

  .edge-pc-instructions {
    width: 530px;
  }

  .edge-pc-image {
    width: 520px;
  }

  .chrome-mac-image {
    width: 459px;
  }

  .safari-mac-instructions {
    width: 656px;
  }

  .safari-mac-image {
    width: 644px;
  }

  .safari-ios2-image,
  .safari-ios-image {
    border: 2px solid grey;
  }

  .ios-settings {
    width: 40px;
    vertical-align: middle;
  }

  .firefox-instructions {
    .button {
      vertical-align: baseline;
      margin-left: 5px;
    }
  }
}