@import "../../styles/colors.scss";

.foyer-document-list {
  margin-top: 5rem;
  padding-bottom: 1rem;

  h1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    color: #989898;
    margin-bottom: 1rem;
  }

  h2 {
    font-weight: bold;
    margin-bottom: 0.7rem;
  }

  .room-docs {
    margin-bottom: 3rem;
  }

  .doc-grid {
    display: grid;
    grid-gap: 22.5px;
    grid-template-columns: repeat(auto-fill, 100px);
    visibility: visible;
  }

  .doc-square {
    height: 100px;
    padding: 0.8rem;
    box-shadow: $foyer-box-shadow;
    background-color: white;
    border-radius: 10px;
    color: #383838;
    &:hover {
      color: initial;
      box-shadow: $foyer-box-shadow-hover;
    }
  }

  .doc-square-content {
    height: 76px;
    width: 76px;
    overflow: hidden;
    word-break: break-word;
    font-size: calc(1rem - 2pt);

    line-height: normal;

    -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 95%);
    mask-image: linear-gradient(to bottom, black 80%, transparent 95%);
  }

  .doc-icon {
    margin-left: 1px;
    color: $sbroyalblue;
    font-size: 16px;
    margin-bottom: 3px;
  }
}
