.progress-line {
  border: 1px solid #ddd;
  border-radius: 1px;
  width: 100%;
  position: relative;
}

.progress-line__inner {
  background-color: #19d0b1;
  position: absolute;
  left: 0;
}

.progress-line__status {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 12px);
  font-weight: 400;
}
