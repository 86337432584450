.EventsSelectItem {
  text-align: left;
  padding: 4px 8px;
  // padding: 4px 8px 4px 0;
  display: flex;
  cursor: pointer;

  &:hover {
    background: #f7f5f5;
  }
}

.EventsSelectItem__label {
  font-weight: bold;
  font-size: 16px;
}


.EventsSelectItem__description {
  font-size: 14px;
}

.EventsSelectItem__content {
  flex: 1 0 0;
}

.EventsSelectItem__icon {
 display: flex;
 align-items: center;
 justify-content: center;
 max-width: 21px;
 min-width: 21px;
 min-height: 21px;
 max-height: 21px;
 border: 1px solid gray;
 border-radius: 4px;
}
