@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "../../styles/colors.scss";

.subscription-card {
  .subscription-row__border-top {
    margin-top: 30px;
    border-top: 1px solid #e8ecee;
    padding-top: 20px;
  }

  .help-text {
    font-size: 12px;
    line-height: 18px;
    color: #6f7782;
    margin-top: 24px;
  }

  .spinner {
    @extend %loader;
  }
}