.subscription-layout {
  .subscription-card {
    padding: 24px;
    background: #fff;
    border: 1px solid #e8ecee;
    border-radius: 8px;
    box-shadow: none;
    transition-duration: 200ms;
    transition-property: background, border-color, box-shadow;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;

    .title {
      margin-bottom: 17px;
      line-height: 20px;
      font-weight: 600;
      font-size: 16px;
      color: #273240;
      text-align: left !important;
    }

    .subscription-row-cols {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      line-height: 24px;
      font-size: 15px;
      color: #151b26;
      margin-top: 15px;
      font-weight: 500;

      &__annual-savings {
        color: #00bf9c;
      }

      &__annual-missing-savings {
        color: #6f7782;
      }
    }

    .subscription-row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      .subscription-column-title {
        line-height: 24px;
        font-size: 14px;
        color: #151b26;
        font-weight: 500;
      }

      .subscription-column-info {
        line-height: 24px;
        font-size: 14px;
        color: #6f7782;
        font-weight: 500;
      }

      .subscription-link-button {
        line-height: 24px;
        font-size: 16px;
        cursor: pointer;
        color: #14aaf5;
        fill: #14aaf5;
      }

      .subscription-column {
        &__bold {
          font-weight: 500;
          color: #151b26;
          line-height: 26px;
          font-size: 22px;
        }
      }

      &__annual-per-month {
        margin: 0;
        opacity: 0.5;
      }
    }

    .stripe-container {
      margin: 24px 0;
    }

    &__actions {
      display: flex;
      justify-content: center;
      margin-top: 24px;

      .subscription__submit-button {
        color: #fff;
        background-color: #129ce1;
        border-color: #008ce3;
        height: 36px;
        line-height: 36px;
        padding: 0 12px;
        align-items: center;
        border-radius: 6px;
        border-width: 1px;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-flex;
        flex-shrink: 0;
        font-size: 15px;
        justify-content: center;
        line-height: 1;
        min-width: 48px;
        text-align: center;
        transition-duration: .2s;
        transition-property: background, border, box-shadow, color, fill;
        border: none;
        width: 100%;
        background-color: #00D1B2;
        font-weight: 600;
        outline: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;


        &:hover {
          background-color: #01B197;
        }

        &:disabled {
          color: #fff;
          fill: #fff;
          background-color: rgba(123, 123, 123, 0.7);
        }
      }
    }
  }
}