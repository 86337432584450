@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "../../styles/colors.scss";

.plain-grey-page {
  background-color: #f5f5f5;
  height: 100%;

  .title-row {
    display: flex;
  }
  .title-image {
    display: inline-block;
    flex-grow: 1;
    padding: 1rem;
  }

  .flex-container {
    display: flex;
    align-items: center;
  }

  .content-column {
    text-align: left;
    margin: auto;
    width: 900px;
    max-width: 900px;
    padding: 30px;
    padding-bottom: 60px;

    &.narrow {
      width: 600px;
      max-width: 600px;
    }
  }

  .content-column label {
    padding-right: 0.5em;
  }

  .message {
    background-color: white;
  }
}
