@import "../../styles/colors.scss";

.dropdown-menu {
  min-width: initial;
}

.dropdown-content {
  .dropdown-item {
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    border: none;
    outline: none;

    svg {
      width: 30px;
      vertical-align: middle;
    }
    .reaction-option {
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}

.participant-name-tag {
  margin-right: 6px;
  display: inline-block;
  vertical-align: text-bottom;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 7px;
  max-width: 100%;
  text-align: left;
  white-space: normal !important;
  height: 100% !important;
  padding: 3px 0;
  position: relative;
  padding-right: 22px !important;
  border-radius: 5px !important;

  &.participant-me {
    padding-right: 57px !important;
  }

  &.audio-only-padding:not(.in-auditorium-tag) {
    padding-right: 38px !important;
  }

  &.participant-me.audio-only-padding:not(.in-auditorium-tag) {
    padding-right: 72px !important;
  }

  .nametag-info {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;

    .icon.is-small {
      padding-left: 4px !important;
    }
  }

  .name-text {
    word-break: break-word;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-right: 3px;
  }

  &:hover {
    background-color: #c3d8ff !important;
  }

  &.currently-focused-tag {
    background-color: #a2c3ff !important;
  }
}

.audio-only-icon {
  margin-left: 8px;
  display: inline-block;
  color: grey;
}

.is-lightblue {
  .audio-only-icon {
    color: royalblue;
  }
}

.audio-only-tag {
  background-color: gainsboro;
}

.participant-nametags-list {
  .event-host-tag {
    background-color: $hostpurple;

    &:hover {
      background-color: darken($hostpurple, 3%) !important;
    }
    .you-label {
      background-color: #f7ebfd;
    }
  }
}

.auditorium-row {
  .participant-nametags-list {
    .can-broadcast-tag {
      // @extend .is-lighterblue;
    }

    // .is-lighterblue {
    //   background-color: $selectedgold2;
    //
    //   &:hover {
    //     background-color: darken($selectedgold2, 3%) !important;
    //   }
    //   .you-label {
    //     background-color: #fff8da;
    //   }
    // }

    .audio-only-padding {
      background-color: white;
      -webkit-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);

      &:hover {
        color: #333;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      .you-label {
        background-color: #e8e8e8;
      }
    }
  }
}
