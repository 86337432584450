@import "./SideMenuContent.scss";

.people-row {
  display: flex;
  padding: 0.2rem 0 0.2rem 1px;
  border-radius: 0.25rem;

  &.status-offline {
    .people-left {
      filter: grayscale(100%);
      opacity: 0.75;
    }
  }

  .people-left {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .people-right {
    flex-grow: 0;
  }

  .person-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
    max-width: 10em;
    overflow: hidden;
    // height: 24px;
    display: inline;
    line-height: 1.4em;
  }

  .join-me-button {
    font-size: 0.8em;
    padding: 0em 0.75em;
    height: 2em;
    line-height: 2em;
  }

  .person-row-icons {
    margin-left: 5px;
    height: 10px;
  }
}

.people-row-list {
  .tippy-box {
    margin-left: 0.7rem;
    border-radius: 6px;
  }
}

.user-office-participants {
  margin-left: 3px;
  padding-left: 0.5rem;
  border-left: 1px solid #a5a5a5;

  .nametag-info {
    position: relative;
    right: inherit;
    top: inherit;
    transform: none;
    margin-left: 0.5rem;
  }
}

.person-row-container:not(.in-this-office) {
  .user-office-participants {
    margin-bottom: 0.5rem;
  }
}

.in-this-office {
  border-radius: 0.3125rem;
  cursor: pointer;

  padding: 0.25rem 0.33rem 0.25rem 0.33rem;
  margin-left: -0.33rem;
  margin-right: -0.33rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  background-color: $here-background;

  .is-white {
    background-color: transparent;
    &:hover {
      background-color: #f3f3f3;
    }
  }
}

.door-is-open-icon {
  color: $sbroyalblue;
}

.person-row-container.hovarable {
  position: relative;
  padding: 0 3px;
  border-radius: 5px;
  cursor: pointer;

  .knocking-button {
    margin-right: -0.3rem;
  }
  .knock-cancel-button {
    margin-right: -0.05rem;
  }
}

.user-chat-new-message-count {
  display: inline-block;
  margin-top: 6px;
  background-color: rgb(0, 209, 130);
  border-radius: 18px;
  height: 16px;
  width: 22px;
  font-size: 0.8rem;
  text-align: center;
  color: rgb(255, 255, 255);
  margin-right: 6px;
}

.person-row-container.hovarable:hover {
  background: rgb(239, 239, 239);

  .join-room-button {
    background-color: rgb(255, 255, 255);
  }

  .knock-cancel-button {
    background: rgb(255, 255, 255);
    border-radius: 15px;
  }

  .knocking-button {
    background-color: rgb(239, 239, 239);
  }

  .join-me-button {
    background-color: rgb(228, 228, 228);
  }

  .join-room-button.is-spurple:hover {
    background-color: rgb(190, 121, 230);
  }
  .join-room-button.is-sblue:hover {
    background-color: rgb(50, 115, 220);
  }
  .join-me-button:hover {
    background-color: rgb(255, 255, 255);
  }
}
