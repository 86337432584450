#root {
  .office-admin {
    .side-menu {
      .title-image {
        margin-bottom: 0.5rem;
      }

      .padding {
        display: block;

        .menu {
          padding-left: 0;
          max-height: calc(100vh - 90px);
          margin-top: 1rem;
          li a {
            padding-left: 1rem;
          }
        }
      }

      .return-button {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        > svg {
          font-size: 0.9rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.office-invite-modal {
  .box {
    padding: 3rem 3rem 2rem 3rem;
  }

  .email-addresses-field {
    margin-bottom: 1.5rem;
  }

  .send-button {
    > svg {
      margin-right: 0.5rem;
    }
  }
}

.slack-app-installed {
  pointer-events: none;

  img {
    opacity: 0.4;
  }
}
