.space-row {
  td {
    height: 30px;
    vertical-align: middle;
  }

  .members-button {
    margin-right: 10px;
    vertical-align: middle;
  }
}
