@import "../../styles/colors.scss";

.help-menu__wrap {
  padding: 10px 14px 14px 14px;
}

.help-menu__section-help-link {
  margin-top: 12px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: #4a4a4a;
  line-height: 1;

  svg {
    margin-left: 3px;
    margin-bottom: -1px;
  }
}

.help-menu__help-button {
  color: $sbroyalblue;
  background: #fff;
  border-color: $sbroyalblue;

  &:hover {
    color: #fff;
    background: $sbroyalblue;
    border-color: $sbroyalblue;
  }
}
