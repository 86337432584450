@import "../../styles/colors.scss";

.rooms-menu > ul > li {
  display: block;
  border-bottom: 1px solid #f1f1f1;
  // margin-bottom: 15px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  line-height: 14px;

  &:first-child {
    padding-top: 0.5rem;
  }

  & + .current-room-row {
  }

  &.auditorium-row {
    // .lobby-name-row {
    //   padding: 8px;
    //   border-radius: 4px;
    //   background-color: $sbmustard;
    // }
    padding: 8px;
    background-color: #f5f5f5;
    border-bottom: none;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  &.current-room-row {
    // padding: 8px;
    // background-color: ghostwhite;
    // border-bottom: none;
    // border-radius: 4px;
  }
}

.rooms-menu li:last-child {
  border-bottom: none;
}

.lobby-name-row {
  display: block;
}

.loading-text {
  font-weight: bold;
  font-size: 18px;
  text-transform: uppercase;
  color: #444;
}

.participant-nametags-list {
  margin-top: 10px;
  margin-bottom: 3px;
}

.room-name {
  padding-right: 0.5em;
  font-weight: 600;
  font-size: 18px;
  color: #444;
  vertical-align: unset;
  display: inline-block;

  &:focus {
    outline: none;
  }
}

.name-text {
  // vertical-align: middle;
}

.rooms-menu {
  .my-label {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }
}

.you-label {
  font-size: 10px;
  text-transform: uppercase;
  color: #333;
  margin-left: 5px;
  background-color: #d4e3ff;
  height: 16px;
  padding: 1px 3px;
  border-radius: 3px;
}

// .room-row-container__current {
//   .participant-name-tag {
//     background-color: map-get($custom-colors, "lighterblue");
//
//     &.participant-me {
//       background-color: map-get($custom-colors, "lightblue");
//     }
//   }
// }
