.landing {
  background-color: white;
  position: absolute;
  height: 100%;
  width: 100%;
}

#logo-container {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  text-align: center;
  color: #333;
  top: calc(50% - 70px);
  left: 50%;
  transform: translate(-50%, -50%);
}

#logo-container svg {
  width: 100%;
  max-width: 500px;
}

#logo-container h2 {
  margin-top: 1em;
  font-weight: 400;
  color: #999;
  font-size: 1.5em;
}
