@import "../../styles/colors.scss";

.chat-widget {
  display: flex;
  flex-direction: column;
  height: 100%;

  .messages {
    // min-height: 200px;
    // max-height: 40%;
    border: 1px solid #f0f0f0;
    margin-top: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
    flex-grow: 1;
  }

  form {
    width: 100%;
    margin-right: 1rem;
  }

  .title {
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 0;
  }
}

#chat-portal,
.dm-chat-wrapper {
  width: 300px;
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;

  .cc1-chat-win-header {
    * {
      visibility: hidden;
    }
  }

  .chat-win-rounded-top {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background-color: white;
    position: relative;
    z-index: 1;
    padding: 9px 1rem;
    padding-bottom: 7px;
    font-weight: bold;
    // font-size: 18px;
    // color: #666;
    border-bottom: 1px solid #f0f0f0;

    cursor: pointer;
    &:hover {
      .level-right {
        color: #3273dc;
      }
    }
  }

  .cc1-chat-win-conver-wrap {
    // margin-bottom: 57px;
    // margin-top: 16px;
    // padding-top: 1rem;
    height: auto;
    max-height: calc(50vh - 150px);
    padding-bottom: 0;
    padding-top: 0.5rem;
  }

  .cc1-chat-win-inpt-ext-wrap {
    position: relative;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;

    &.chat-win-inpt-no-messages {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }
  }

  .cc1-chat-win-file-popup {
    text-align: left;
    background: rgba(255, 255, 255, 0.8);
  }

  .cc1-chat-win-inpt-int-wrap .cc1-chat-win-inpt-send,
  .cc1-chat-win-inpt-attach {
    width: 45px;
  }

  .show-portal {
    visibility: visible;
  }

  .hide-portal {
    visibility: hidden;
    * {
      visibility: hidden;
    }
  }

  .cc1-chat-win-sndr-msg-wrap {
    background-color: $sbroyalblue;
  }

  .cc1-chat-win-inpt-send {
    margin-top: 5px;
  }

  .cc1-chat-win-inpt-send-btn {
    path {
      fill: $sbcarribean;
    }
  }

  .cc1-chat-win-rcvr-msg-wrap {
    background-color: white;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
  }

  .cc1-chat-win-rcvr-name {
    font-size: 15px;
    font-weight: bold;
    color: #555;
    line-height: 17px;
  }

  .cc1-chat-win-rcvr-row .cc1-chat-win-msg-time-wrap,
  .cc1-chat-win-sndr-row .cc1-chat-win-msg-time-wrap {
    margin: 0;
    line-height: 12px;
    opacity: 0;
  }

  .cc1-chat-win-sndr-row {
    margin-bottom: 11px;

    .cc1-chat-win-timestamp {
      opacity: 0;
      font-size: 11px;
      color: #aaa;
      padding-right: 0.5rem;
    }

    &:hover {
      .cc1-chat-win-timestamp {
        opacity: 1;
      }
    }
  }

  .cc1-chat-win-rcvr-row {
    .chat-txt-msg .cc1-chat-win-timestamp {
      font-size: 11px;
      color: #aaa;
      padding-left: 0.5rem;
      opacity: 0;
    }

    &:hover {
      .chat-txt-msg .cc1-chat-win-timestamp {
        opacity: 1;
      }
    }
  }

  .chat-txt-msg {
    line-height: 17px;
  }

  .cc1-chat-win-rcvr-row,
  .cc1-chat-win-sndr-row {
    margin-bottom: 0;

    &:hover {
      .cc1-chat-win-msg-time-wrap {
        opacity: 1;
      }
    }
  }

  .cc1-chat-win-inpt-wrap {
    .input {
      background-color: #f1f1f1;
      border: 0;
      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: #aaa;
      }
    }
  }

  .cc1-chat-win-inpt-int-wrap .cc1-chat-win-inpt-attach span {
    margin-top: 9px;
  }

  .hide-chat-win-conver {
    display: none;
  }

  .cc1-chat-win-msg-block {
    padding-bottom: 0.75rem;
  }

  .cc1-chat-win-sndr-row {
    .cc1-chat-win-msg-block {
      padding-bottom: 0.5rem;
    }
  }

  .cc1-chat-win-rcvr-row
    .cc1-chat-win-msg-block
    .cc1-chat-win-rcvr-dtls
    .cc1-chat-win-msg-time-wrap
    .cc1-chat-win-timestamp {
    color: #aaa;
  }

  .cc1-chat-win-rcvr-dtls {
    margin-left: 0;
  }
}

.dm-chat-wrapper.user-chat {
  position: static;
}
