@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";

.call-tiles {
  width: 100%;
  height: 100%;
}

.call-loading {
  width: 100%;
  height: 100%;
  color: white;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);

  .call-loader-spinner {
    @extend %loader;
    margin-left: 0.5rem;
    display: inline-block;
  }
}

.call-tiles-transition-enter {
  opacity: 0;
}
.call-tiles-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.call-tiles-transition-enter-done {
  opacity: 1;
}

.call-tiles-transition-exit {
  opacity: 1;
}
.call-tiles-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
