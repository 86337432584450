@import "../../../styles/colors";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";

.call-management {
  .url-col {
    width: 50%;
  }

  .create-call-loading {
    display: inline-block;
    padding-left: 14px;
    padding-top: 10px;

    &::after {
      @extend %loader;
      display: inline-block;
    }
  }

  .create-call-button {
    margin-bottom: 1rem;
  }

  .no-sessions {
    margin-top: 8px;
    padding: 2px 8px;
    color: grey;
  }
}

.tippy-box.blue-tippy {
  background-color: $sbmustard;
  color: black;
}