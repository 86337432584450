@import "../../styles/colors.scss";
@import "../../styles/shared.scss";

.join-spaces {
  display: flex;
  justify-content: center;

  .join-spaces-scroller {
    width: 100%;
    min-height: 50px;
    max-height: calc(100vh - 15rem);
    overflow-y: auto;
    padding-top: 0.25rem;
    padding-right: 0.5rem;
    padding-bottom: 0.25rem;
    font-size: 0.9333333rem;
  }

  .join-spaces-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    cursor: pointer;

    &:hover {
      .fancy-checkbox {
        border-color: #777;
      }
      .space-name-box {
        background-color: #f7f7f7;
        border-color: darken($spaceOutline, 1);
      }
    }

    &.checked {
      .space-name-box {
        background-color: #f3f7ff;
        border-color: #dae6ff;
      }

      .fancy-checkbox {
        @extend .fancy-checkbox.checked;
      }

      &:hover {
        .space-name-box {
          background-color: darken(#f3f7ff, 1);
          border-color: darken(#dae6ff, 1);
        }
      }
    }
  }

  .checkbox-container {
    width: 4em;
    display: flex;
    justify-content: center;
  }

  .space-name-container {
    flex-grow: 1;
  }

  .space-name-box {
    @extend .space-box-padded;
    padding-left: 0.75em;
    border-color: $spaceOutline;
    width: 100%;
  }
  .space-name {
    font-weight: bold;
  }
  .space-popuplation {
    font-size: 0.75em;
  }
}

.fancy-checkbox {
  width: 1.25em;
  height: 1.25em;
  border: 1px solid #aaa;
  border-radius: 0.33em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;

  &.checked {
    background-color: $blue;
    border-color: $blue;
    color: white;
  }

  &:hover {
    border-color: #777;
  }
}
