@import "../../styles/colors.scss";

.change-photo {
  display: flex;
  flex-direction: column;

  .current-photo {
    margin-bottom: 1rem;
    display: flex;
    justify-content: left;
  }

  .delete-photo-icon {
    display: inline-block;
    vertical-align: top;
    color: grey;
    padding: 0 0.5em 0.5em 0.5em;
    cursor: pointer;
    margin-top: -0.2em;

    &:hover {
      color: darken(red, 5);
    }
  }

  .file {
    justify-content: center;
  }

  .change-photo-cropper {
    position: relative;
    width: 400px;
    height: 300px;
    margin-bottom: 1.5rem;
  }
  .change-photo-actions {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .change-photo-save {
      margin-right: 1rem;
    }

    .loader {
      font-size: 1rem;
      margin-left: 0.5rem;
    }
  }
}
.change-photo-container {
  display: flex;
  justify-content: center;
}
