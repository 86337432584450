@import "../../styles/colors.scss";

.plain-grey-page,
.plain-photo-page {
  background-color: #f5f5f5;
  height: 100%;
  overflow: auto;

  a {
    color: $sbroyalblue;

    &:hover {
      color: #1052bd;
    }
  }

  .title-row {
    display: flex;

    &.hide-logo-mobile {
      visibility: visible;
      @media (max-width: 1100px) {
        visibility: hidden;
      }
    }
  }
  .title-image {
    display: inline-block;
    flex-grow: 1;
    padding: 1rem;
    svg {
      width: 188px;
    }

    &.blue-logo {
      width: inherit !important;
      svg {
        width: inherit !important;
      }
    }
  }

  .flex-container {
    display: flex;
    align-items: center;

    &.tall {
      height: 100%;
    }
  }

  .content-column {
    text-align: left;
    margin: auto;
    width: 900px;
    max-width: 900px;
    padding: 30px;
    padding-bottom: 40px;
    position: relative;

    &.narrow {
      width: 600px;
      max-width: 600px;
    }

    &.narrower {
      width: 500px;
      max-width: 500px;
    }

    &.shorter {
      padding-bottom: 30px;
    }

    &.high {
      margin-top: -54px;
      padding-top: 0;
    }

    &.tall {
      height: 100%;
    }
  }

  .content-column label {
    padding-right: 0.5em;
  }

  .message {
    background-color: white;
  }
}

.plain-photo-page {
  background-image: url("/assets/images/mountains.jpg");
  background-size: cover;

  .content-column {
    border-radius: 0.33rem;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  }

  .flex-container {
    height: calc(100% - 64px);
    padding-bottom: 20vh;
  }

  .blue-logo {
    path {
      fill: white;
    }
  }
}
