@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "../../styles/colors.scss";

.Overlay__preview__modal {
  transition: opacity 100ms ease-in-out;
  z-index: 2;
  background: rgba(10, 10, 10, 0.86);
  position: fixed;
  inset: 0px;
}

.ReactModal__Content.ReactModal__Content__preview__modal {
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  outline: none;
  margin-top: 0;

  .preview-modal {
    padding: 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__title {
      font-size: 24px;
      font-weight: bold;
      margin-top: 3px;
      margin-bottom: 15px;
      align-self: flex-start;

      p {
        font-size: 1rem;
      }
    }

    &__content {
      width: 400px;
      min-height: 300px;
      margin-bottom: 20px;
      display: flex;
      border-radius: 10px;

      position: relative;

      video {
        border-radius: 10px;
        min-width: 100% !important;
        min-height: 230px;
        max-height: 300px;
        overflow: hidden;
        transform: scaleX(-1);
      }

      .preview-modal__pending-permissions {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        width: 100% !important;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 12px;
        font-size: 30px;
        padding: 0 70px;
        text-align: center;

        .preview-modal__spinner {
          @extend %loader;
        }
      }
    }

    &__camera-container,
    &__pending-permissions {
      width: 100%;
      height: 300px;
      display: flex;
    }

    &__selects {
      margin-top: 5px;
      width: 95%;
      margin-bottom: 15px !important;
    }

    .preview-modal__permission-error,
    .preview-modal__permission-error__simple {
      width: 100%;

      &__simple {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 500px;
        margin-bottom: 20px;
        display: flex;
      }

      img:not(.ios-settings) {
        width: 100%;
        margin-bottom: 5px;
        border-radius: 12px;
      }

      .ios-settings {
        width: 40px;
        vertical-align: middle;
      }

      .preview-modal__action-row {
        margin-bottom: 15px;
        color: #818181;
        font-weight: 600;
        font-size: 15px;

        a {
          color: #00d1b2;
          font-weight: bold;
        }
      }
    }

    &__selects {
      margin-bottom: 20px;

      &__select-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        font-size: 14px;

        svg {
          font-size: 30px;
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      button {
        font-size: 18px;
        cursor: pointer;

        span {
          display: flex;
          align-items: center;
        }

        svg {
          font-size: 25px;
          margin-right: 8px;
        }
      }
    }
  }
}
