@import "../../styles/colors.scss";

.chat-tabs {
  width: 236px;
  display: flex;

  .chat-tab {
    flex: 1;
    background: none;
    border: 1px solid #a7a7a7;
    color: #777777;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 6px;
    outline: none !important;
    cursor: pointer;
    position: relative;

    text-overflow: ellipsis;
    // overflow: hidden;
    white-space: nowrap;

    &:first-child:not(.tab-active) {
      border-right: none;
    }

    &:last-child:not(.tab-active) {
      border-left: none;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }

    &.tab-active {
      border-color: $sbroyalblue;
      color: $sbroyalblue;
      background: $sblinkwater;
      font-weight: 500;
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }

    & > .new-message-count {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -8px;
      right: -3px;
      z-index: 1;
      height: 18px;
      width: 18px;
      border-radius: 12px;
      margin: 0;
      font-weight: 900;
      font-size: 10px;
      color: #ffffff;
    }

    & > .chat-tabs-label {
      max-width: 105px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
