@import "../../styles/colors.scss";

.documents-tippy {
  & > .tippy-content {
    padding: 5px 7px;
  }
}

.documents-popup {
  padding-top: 0.5rem;
  min-width: 12rem;
  min-height: 16rem;
  max-height: calc(60vh - 100px);
  display: flex;
  flex-direction: column;
  max-width: 14rem;

  .new-document-button {
    font-size: 0.6666666667rem;
    padding-left: calc(1em + 0.4em);
    padding-right: calc(1em + 0.4em);
    svg {
      font-size: 0.866666667rem;
    }

    .new-document-button-label {
      font-size: 0.8rem;
      margin-left: 0.25rem;
    }
  }

  .documents-scroller {
    overflow-y: auto;
    flex-grow: 1;
  }

  .documents-list {
    .menu {
      font-size: 1em;
      .menu-label {
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 0.9em;
        font-weight: bold;
        letter-spacing: normal;
      }
    }

    .menu-list a {
      padding: 0.3em 0.45em;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3rem;
    padding-top: 5px;
    justify-content: center;

    > div:not(:first-child) {
      margin-left: 0.5rem;
    }
  }

  .doc-row-options {
    align-items: center;
    display: inline-flex;
    visibility: hidden;
    color: #bbbbbb;
    margin-left: 0.5rem;
    align-self: flex-start;
    margin-top: 2px;
    &:hover {
      color: #333333;
    }
  }

  .doc-name {
    flex-grow: 1;
    display: flex;
    overflow: hidden;
    align-items: center;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline;
      overflow: hidden;
      max-width: 10rem;
    }

    svg,
    img {
      margin-right: 0.3rem;
      width: 16px;
      height: 16px;
      color: $sbroyalblue;
    }
  }

  .doc-row-link {
    display: flex;
    &:hover {
      .doc-row-options {
        visibility: visible;
      }
    }
  }
}
