@import "../../styles/colors.scss";

.cluster-list,
.ghost-cluster-list {
  min-width: 200px;
  padding: 10px;

  .cluster-padding {
    position: relative;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .cluster-preview {
    display: grid;
    position: relative;
    grid-template-columns: 78px 78px;
    grid-auto-rows: 78px;
    column-gap: 8px;
    row-gap: 8px;

    border-radius: 6px;
    padding: 8px;
    min-height: 94px;

    &__overlay {
      display: none;

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.4);

      justify-content: center;
      align-items: center;

      button {
        // background-color: #407ef6 !important;
        // color: white !important;
        // background-color: white !important;
        // border-color: #407ef6 !important;
        color: #407ef6;
        height: 2em;
      }
    }

    &:hover {
      .cluster-preview__overlay {
        display: flex;

        button {
          background-color: #407ef6 !important;
          color: white !important;
        }
      }
    }

    &--current {
      background-color: $sbsteelgrey;
    }

    &--join-highlighted {
      // background-color: #222;
    }

    &--joining {
      // visibility: hidden;
    }

    &__joining-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #222;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      .loader {
        opacity: 0.1;
        font-size: 150%;
      }
    }

    &__joining-animation {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.8);
      // background-color: red;

      &.cluster-node-exit {
        opacity: 1;
        transform: translateX(0);
        width: 100%;
        height: 100%;
      }
      &.cluster-node-exit-active {
        opacity: 0;
        transform: translateX(-10vw) scaleX(1.8) scaleY(3);
        transition-property: all;
        transition-duration: 150ms;
        transition-timing-function: linear;
      }
    }
  }

  .cluster-thumbnail {
    width: 78px;
    height: 78px;
    position: relative;
    cursor: pointer;
    background-color: #222;
    border-radius: 10px;

    &:hover {
      .cluster-thumbnail__name {
        display: block;
      }
    }

    video {
      width: 78px;
      height: 78px;
      border-radius: 10px;
      object-fit: cover;
    }

    &__camless-tile {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 32px;
      width: 78px;
      height: 78px;
      border-radius: 10px;
      background-color: #111;
    }

    &__name {
      display: none;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      color: #eee;
      text-align: center;
      font-size: 12px;
      z-index: 1;

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    .name-test {
      background-color: green;
      color: red;
      font-size: 200px;
      z-index: 100000;
      position: absolute;
      left: 0;
      top: 0;
      display: block;
    }
  }

  .cluster-join-thumbnail {
    // background-color: rgba(255, 255, 255, 0.05);
    background-color: #407ef6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    button.is-link {
      background-color: rgba(0, 0, 0, 0.1);
      &:hover {
        background-color: rgba(0, 0, 0, 0.15);
      }
    }
  }

  .cluster-preview__overflow-count {
    font-size: 90%;
    text-align: center;
    width: 100%;
    margin-top: -5px;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    z-index: 3;
  }

  .cluster-preview__overflow-name {
    cursor: pointer;
    padding: 5px 8px;
    &:hover {
      color: white;
      background-color: #333;
    }
  }
}

.ghost-cluster-list {
  position: absolute;
  top: 0;
  left: -10px;
  z-index: 3;
  padding-top: 0;
  // background-color: red;
}
