.doc-editors-popup {
  overflow-x: hidden;
  padding-bottom: 0.25rem;
  .editor-list {
    margin-top: 0.5rem;

    & > li {
      display: flex;
      align-items: center;
      padding-bottom: 0.25rem;
      border-bottom: 1px solid grey;
      margin-bottom: 0.25rem;
    }

    & > li:last-child {
      padding-bottom: 0;
      border-bottom: none;
      margin-bottom: 0;
    }
  }
  .editor-name {
    flex-grow: 1;
    word-break: break-word;
  }
  .kick-editor-button {
    font-size: 10px;
    font-size: normal;
    margin-left: 0.5rem;
  }
}
