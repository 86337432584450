.doc-pane {
  display: flex;
  flex-direction: column;
  margin: 1rem;

  .doc-header {
    display: flex;
    align-items: baseline;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 0.5rem 1rem;
    background-color: #d1d1d1;
  }

  .doc-title {
    color: inherit;
    font-size: 1.25em;
    font-weight: bold;
    line-height: normal;
    flex-grow: 1;
    & > span {
      margin-right: 0.75rem;
      vertical-align: middle;
    }

    .editable-name {
      display: inline;
      outline: none;
      margin-right: 0.1rem;
      line-height: 1rem;
      padding: 0 0.13rem;
      word-break: break-word;

      &:hover {
        background-color: white;
        border-radius: 4px;
      }
      &:focus {
        background-color: lemonchiffon;
        border-radius: 4px;
      }
    }
  }

  .doc-closer {
    width: 1rem;
    font-size: 1.25rem;
    color: #888888;
    cursor: pointer;

    svg {
      vertical-align: middle;
    }

    &:hover {
      color: #565656;
    }
  }

  .editor-wrapper {
    height: calc(100% - 35px);
    width: 100%;
    display: flex;
    flex-grow: 1;

    .quill {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .ql-snow {
      background-color: white;
    }

    .ql-toolbar {
      &.ql-snow {
        border-left: none;
        border-top: none;
        border-right: none;
      }
    }

    .ql-container {
      height: calc(100% - 43px);
      display: flex;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      .ql-editor {
        width: 100%;
      }
    }

    .ql-container,
    .ql-editor {
      font-size: 15px;
    }
  }

  .doc-paper {
    width: 100%;
    padding: 8px 0px;
    padding-bottom: 7px;
    padding-right: 1px;
    background-color: white;
    color: #363636;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 13px;
    line-height: 1.42;
    font-family: Helvetica, Arial, sans-serif;
    margin-bottom: 2px;
  }

  .doc-scroller {
    overflow-y: auto;
    height: 100%;
    padding: 4px 15px 12px 15px;

    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  .doc-edit-button {
    font-size: 13px;
    height: 1.5rem;
    padding: 0.6rem;
    vertical-align: middle;
    &:focus {
      border-color: transparent !important;
    }
    .is-focused {
      border-color: transparent;
    }
  }

  .doc-stop-button {
    &:focus {
      border-color: transparent !important;
    }
    .is-focused {
      border-color: transparent;
    }
  }

  .doc-pane-settings-button {
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
    color: #888888;
    cursor: pointer;
    &:hover {
      color: #565656;
    }
  }
}

.doc-page {
  height: 100%;

  .doc-pane {
    height: 100%;
    .doc-header {
      background-color: #e5e5e5;
    }
    .doc-title {
      color: #363636;
    }

    .doc-closer {
      display: none;
    }

    .doc-pane-settings-button {
      color: #888888;
      &:hover {
        color: #363636;
      }
    }
  }
}
