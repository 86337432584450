.change-photo-modal {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 600px;

  .modal-title {
    display: flex;
    justify-content: center;
  }
  .change-photo-container {
    position: relative;
    padding: 1rem 6rem;
  }
}
