.tray-button {
  height: 44px;
  border: none;
  background-color: transparent;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  min-width: 45px;
}

.tray-button:disabled {
  opacity: 0.33;
}

.tray-button:enabled {
  cursor: pointer;
}

.tray-button:focus {
  outline: none;
}

/* Acts as the first button in a new "group" of buttons separated from those to the left of it */
.tray-button.new-group {
  margin-left: auto;
}
