@import "../../styles/colors.scss";

.ReactModal__Overlay__disabled {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(10, 10, 10, 0.5) !important;
}

.ReactModal__Content__modal {
  outline: none;
  position: fixed;
  left: 50%;
  top: 33%;
  transform: translate(-50%, -33%);
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  max-width: 95vw;

  &.ReactModal__Content__corner {
    right: 80px;
    bottom: 100px;
    transform: none;
    top: unset;
    left: unset;
    max-width: 700px;
  }

  &.ReactModal__Content {
    padding: 0;
  }

  .modal-wrapper {
    position: relative;
  }

  .modal-close-wrap {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1;
  }

  .modal-header {
    align-self: center;
    color: #151b26;
    flex: 1;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    min-width: 1px;
    overflow-wrap: break-word;
    padding: 8px 32px;
    border-bottom: 1px solid #e8ecee;
  }

  .modal-content {
    color: #818181;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
    width: 100%;
    text-align: justify;
    display: flex;
    margin: 0;

    .subscription-container {
      width: 600px;
      display: flex;
      padding: 16px 32px;

      & > div:first-child {
        width: 60%;
        display: inline-block;
        flex: 1 1 auto;
      }

      & > div:nth-child(2) {
        width: 36%;
        display: inline-block;
        flex: 0 0 auto;
      }

      .subscription-item {
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;

        &__title {
          font-size: 16px;
          line-height: 20px;
          color: #6f7782;
          display: inline-block;
          font-weight: 500;
        }

        &__content {
          width: 100%;
        }

        &__title-price {
          line-height: 28px;
          font-size: 22px;
          color: #151b26;
          font-weight: 500;
          text-align: left;
        }

        &__subtitle {
          font-size: 12px;
          line-height: 14px;
          margin-top: 8px;
        }
      }

      .subscription-total {
        background-color: #f6f8f9;
        border: 1px solid #e8ecee;
        border-radius: 4px;
        flex: 0 0 auto;
        height: 100%;
        margin-left: 24px;
        padding-top: 16px;

        .subscription-item {
          padding: 0 16px 16px;
          margin: 0;
        }

        .subscription-item__title {
          margin: 0;
        }
      }
    }
  }

  .small-text {
    display: inline-block;
    font-size: 18px;
  }

  .subscription-message {
    margin: 20px 32px;
    text-align: center;
  }

  .modal-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 16px;

    .subscription__submit-button {
      color: #fff;
      background-color: #129ce1;
      border-color: #008ce3;
      height: 36px;
      line-height: 36px;
      padding: 0 12px;
      align-items: center;
      border-radius: 6px;
      border-width: 1px;
      box-sizing: border-box;
      cursor: pointer;
      display: inline-flex;
      flex-shrink: 0;
      font-size: 15px;
      justify-content: center;
      line-height: 1;
      min-width: 48px;
      text-align: center;
      transition-duration: 0.2s;
      transition-property: background, border, box-shadow, color, fill;
      border: none;
      min-width: 40%;
      background-color: #00d1b2;
      font-weight: 600;
      outline: none;

      &:hover {
        background-color: #01b197;
      }

      &:disabled {
        color: #fff;
        fill: #fff;
        background-color: rgba(123, 123, 123, 0.7);
        cursor: default;
      }
    }
  }

  .payment-container {
    padding: 16px 32px;

    .stripe-container {
      margin-bottom: 20px;
    }

    .modal-actions {
      margin-top: 30px;
    }
  }
}
