.banned-users-container {
  table {
    .unban-user {
      cursor: pointer;
      display: inline;

      svg {
        color: rgba(54, 54, 54, 0.7);

        &:hover {
          color: rgb(54, 54, 54);
        }
      }
    }
  }

  .banned-users-table-box {
    padding-bottom: 3rem;
  }
}
