@media only screen and (max-width: 768px) {
  .browser-unsupported-container {
    .browser-unsupported {
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
    }
  }
}

.browser-unsupported {
  position: absolute;
  background: #ffffff;
  /* font-family: Helvetica Neue;
  font-size: 14px;
  // line-height: 17px; */
  /* text-align: center; */
  color: #4a4a4a;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;

  .browser-warning {
    padding-bottom: 14px;
  }

  h4 {
    font-weight: 600;
    padding-top: 14px;
    font-size: 18px;
  }

  &.black-bg {
    background-color: black;
  }
}

.browser-unsupported svg {
  width: 200px;
  display: block;
  margin: 0 auto;
}
