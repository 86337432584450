form.register-form {
  display: flex;
  flex-direction: column;

  input.form-component__input {
    height: 48px;
  }

  div.form-component__error {
    line-height: 1rem;
  }

  .form__two-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &>div {
      width: 49%;
    }
  }

  h1 {
    text-align: center;
  }

  .button-submit {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 20px;
    background-color: #00D1B2;
    font-weight: 600;
    font-size: 15px;
    outline: none;

    &:hover {
      background-color: #01B197;
    }

    &:disabled {
      background-color: #bdbbb9;
    }
  }

  .title {
    margin-bottom: 10px;
    text-align: left;
    font-size: 2.5rem;
    font-weight: 500;
  }

  .subtitle {
    text-align: left;
    margin-bottom: 40px;
    font-size: 15px;
  }

  .terms {
    margin-top: 8px;
    font-size: 14px;
    text-align: left;
    line-height: 1.25rem;
  }

  .password-requirements {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    .password-requirement {
      width: 49%;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 0;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #00D1B2;
        margin-right: 12px;
      }

      &__valid {
        color: #bdbbb9;

        .circle {
          background-color: #bdbbb9;
        }
      }
    }
  }
}