.slack-user-list {
  margin-left: 0 !important;

  li {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: whitesmoke;
    }
  }

  button {
    border-radius: 4px !important;
    margin-right: 0.4rem;
  }

  img {
    border-radius: 4px;
    margin-right: 0.4rem;
    height: 24px;
    width: 24px;
  }

  .slack-loader {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;

    .loader {
      display: block;
      font-size: 5rem;
      margin-bottom: 1rem;
    }

    div {
      display: block;
    }

    .loader-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.slack-sent-request-message {
  .slack-style-mention {
    display: inline-block;
    color: #12558b;
    line-height: 1.5;
    padding-left: 1px;
    padding-bottom: 2px;
    padding-right: 1px;
    border-radius: 4px;
    font-weight: normal;
  }
}
