.profile-page {
  max-width: 500px;

  .change-email-title {
    margin-bottom: 1rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
