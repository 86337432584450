.call-message {
  width: auto;
  padding: 20px 30px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-align: left;
  font-size: 14px;
  line-height: 17px;

  .call-message.error {
    color: #d81a1a;
    /* background-color: #ffffff; */
  }

  .call-message-header {
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.auditorium-message {
  max-width: 600px;
  min-width: 400px;
  padding: 20px 30px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: left;

  .message {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    font-size: 17px;
    line-height: 24px;

    .message-body {
      color: #000000;
      border: none !important;

      .call-message-header {
        font-weight: bold;
        margin-bottom: 20px;
        font-size: 20px;
      }
    }
  }
}
