.space-box {
  align-items: center;
  border: 3px solid $spaceOutline;
  border-radius: 3px;
}

.space-box-padded {
  @extend .space-box;
  padding: 0.5rem;
}

.ReactModal__Content__modal {
  max-height: 100vh;
  overflow-y: auto;
  margin-top: 0;
}

.icon-button-on-white {
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
}

.tippy-menu-padding {
  padding: 0.5rem 0.3rem 0.5rem 0.3rem;

  .tippy-content {
    padding: 0.333rem;
  }

  .menu-list {
    padding: 1.23rem 1.23rem 0 1.05rem;

    &:first-child {
      padding-top: 0.6rem;
    }
  }
}
