@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "../../styles/colors.scss";

.login-modal-padding {
  padding: 1rem;
  padding-bottom: 1.2rem;

  .login-loading {
    display: inline-block;
    padding-top: 10px;
    padding-left: 14px;
    &::after {
      @extend %loader;
      display: inline-block;
    }
  }

  .login-success {
    color: $sbcarribean;
  }
}
