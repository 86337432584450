.debug-console {
  padding: 0.75rem;
  margin-bottom: 5px;
  background-color: white;
  border: 1px solid grey;
  z-index: 2;
  width: 100%;
  font-size: 14px;
  line-break: anywhere;
  overflow-y: auto;

  .button {
    margin-right: 5px;
  }
}
