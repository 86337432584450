.microphone-list {
}

.microphone-list-label {
  cursor: pointer;
  background-color: #00d1b2;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 6px 23px 12px 23px;
  font-size: 20px;
  border-bottom-left-radius: 3px;
  font-weight: bold;

  span {
    vertical-align: middle;
  }
  svg {
    margin-left: 6px;
    height: 16px;
    vertical-align: middle;
  }

  .on-mic {
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
    text-align: center;
  }
}

.microphone-list-tippy {
  .tippy-content {
    padding: 1rem;
    .button {
      padding: 0;
      height: initial;
    }
  }
}
