@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "../../styles/colors.scss";

.side-menu {
  background-color: white;
  height: 100%;
  position: relative;
  // grid-area: nav;
  width: 300px;
  max-width: 50vw;

  &:hover {
    .collapse-button svg path {
      fill: #333 !important;
    }
  }

  .collapse-button {
    &:hover {
      svg path {
        fill: #ec181c !important;
      }
    }
  }
}

.two-sections {
  display: flex;
  flex-direction: column;
  height: calc(100% - 43px);

  .rooms-menu {
    flex-grow: 1;
  }
  .bottom-menu {
    display: flex;
    height: 60px;
    padding-right: 1rem;
  }
}

.side-menu.hide-side-menu {
  display: none;
}

.side-menu .padding {
  padding: 1rem 0 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.side-menu .title-image {
  margin-bottom: 1rem;
  width: 188px;
  max-width: calc(100% - 50px);
  margin-left: 1rem;
}

.side-menu .title-image svg {
  // width: 100%;
  // height: 100%;
}

.rooms-menu {
  font-size: 1.5em;
  text-align: left;
  overflow-x: hidden;
  padding-right: 1rem;
  padding-left: 16px;

  ul {
    padding: 0;
    margin: 0;
  }

  ul > li > div:not(.lobby-name-row) {
    // display: inline-block;
  }
}

.collapse-button {
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 15px;
}

.collapse-button svg,
.show-button > svg {
  width: 24px !important;
  height: 24px !important;
}

.collapse-button svg path {
  fill: #777777 !important;
}

.show-button {
  padding: 0.75rem 0 0.8rem 1rem;
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
  display: flex;
  background-color: rgba(255, 255, 255, 0.95);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 20px;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
    .show-icon svg path {
      fill: $sbroyalblue !important;
    }
  }

  .show-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: 2px;
    margin-right: 12px;
    svg {
      width: 24px;
      height: 24px;
      vertical-align: baseline;
    }
    svg path {
      width: 30px;
      fill: #777;
    }
  }
}

.sidebar-icon {
  // height: 28px;
  overflow-x: hidden;
  width: 42px;
  display: inline-block;
  svg {
    width: 188px;
    // height: 23px;
  }
}

.button.is-rounded.is-circular {
  padding: 1px 1em;
  svg {
    width: 100%;
    height: 100%;
  }
}

.copy-instructions {
  margin-bottom: 0.5em;
}

.modal-content {
  margin: inherit;

  .message-body {
    position: relative;
    .delete {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
}

.modal-background,
.modal-background-clear {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;

  /* Probably need media queries here */
  max-width: 100%;
  height: 100%;
  max-height: 100%;

  z-index: 100;
}

.custom-modal {
  &:focus {
    outline: none;
  }
}

.rooms-menu .add-room-button-row {
  margin-bottom: 5px;
}

.rooms-menu .add-room-button {
  // width: 26px;
  color: #407ef6;
  display: inline;
  text-align: center;
  cursor: pointer;
  user-select: none;
  .button {
    vertical-align: middle;
  }
}

.creating-status {
  display: inline-block;
  margin-left: 10px;
  line-height: 24px;
  font-size: 12px;
  vertical-align: middle;
  &::after {
    @extend %loader;
    display: inline-block;
    margin-left: 5px;
    font-size: 13px;
  }
}

.call-login-menu {
  width: 100%;
  padding-left: 16px;

  button.is-white {
    background-color: #f7f7f7;
    &:hover {
      background-color: white;
    }
  }

  .call-login-button {
    // font-size: 14px;
    color: #777;
  }

  .call-user-menu-button {
    border: none;
    font-size: 25px;
    color: #555555;
    cursor: pointer;

    &:hover {
      color: #111111;
    }
  }

  .menu-list {
    a svg {
      margin-left: 3px;
      margin-bottom: -1px;
    }
  }
}

.add-room-button {
  .icon {
    font-size: 1rem;
  }
}
