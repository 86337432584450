@import "~bulma/sass/utilities/_all.sass";
@import "../../styles/colors.scss";

.tray-container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  display: flex;
  pointer-events: none;
  z-index: 1;
}

#office-container:not(.no-side-menu) {
  .tray-left-spacer {
    width: 22rem;
    min-width: 22rem;
  }
}

.tray-left-spacer {
  pointer-events: none;
  height: 60px;
  width: 300px;
  min-width: 300px;
  max-width: 50vw;

  &.side-menu-hidden {
    width: 0;
    visibility: hidden;
    min-width: 0;
  }

  @media screen and (max-width: 600px) {
    &:not(.side-menu-hidden) {
      min-width: 50vw;
      max-width: 50vw;
    }
  }
}

.tray {
  pointer-events: auto;
  flex: none;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #222;
  position: relative;
  flex-grow: 1;

  &:not(.side-menu-hidden) {
    @media screen and (max-width: 1055px) {
      .alignment-offset {
        width: clamp(1px, 1vw, 102px);
      }

      .debug-spacer {
        width: clamp(1px, 1vw, 29px);
      }

      .flex-spacer ~ .flex-spacer {
        flex-grow: 0;
      }
    }

    @media screen and (max-width: 932px) {
      .flex-spacer ~ .flex-spacer {
        flex-grow: 1;
      }
    }
  }

  &.side-menu-hidden {
    @media screen and (max-width: 745px) {
      .alignment-offset {
        width: clamp(1px, 1vw, 102px);
      }

      .debug-spacer {
        width: clamp(1px, 1vw, 29px);
      }

      .flex-spacer ~ .flex-spacer {
        flex-grow: 0;
      }
    }

    @media screen and (max-width: 623px) {
      .flex-spacer ~ .flex-spacer {
        flex-grow: 1;
      }
    }
  }

  @media screen and (max-width: 900px) {
    &:not(.side-menu-hidden) {
      .chat-button-title {
        display: none;
      }

      .alignment-offset {
        display: none;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .chat-button-title {
      display: none;
    }

    .alignment-offset {
      display: none;
    }

    &:not(.side-menu-hidden) {
      max-width: 50vh;
    }
  }

  @include mobile {
    &:not(.side-menu-hidden) {
    }

    .tray-button {
      padding: 0;
      // margin: 5px;
    }
  }

  .troubleshooting-button {
  }

  .chat-button,
  .exit-button {
    font-size: 16px;
    color: white;
    font-weight: 500;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    cursor: pointer;
    user-select: none;

    &.disabled {
      opacity: 0.33;
      cursor: default;
      pointer-events: none;
    }
  }

  .chat-button {
    position: relative;
    // left: 0;
    // top: 9px;
    // height: 44px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    flex-flow: nowrap;
    padding-right: 0;
    margin-right: 5px;

    .chat-button-title {
      align-self: center;
      vertical-align: middle;
    }

    .tray-button {
      margin-top: 3px;
    }

    .new-message-count {
      position: absolute;
      top: 9%;
      left: 55%;
      z-index: 1;
      background-color: $sbcarribean;
      border-radius: 14px;
      height: 18px;
      width: 25px;
      align-self: center;
      text-align: center;
      font-size: 12px;
    }
  }

  .custom-tray-item {
    align-items: center;
    display: flex;
    color: #fff;
  }

  .exit-button {
    color: #fb5554;
    text-transform: uppercase;
    border: 1px solid #fb5554;
    border-radius: 20px;
    height: 35px;
    padding: 0 16px;
    margin-left: 8px;

    &:hover {
      border-color: #fa4645;
      background: #fa4645;
      color: #fff;
    }
  }

  .documents-tray-button {
    color: white;
    font-size: 1.466666667rem;
    margin-left: 3px;
  }

  .collapsed-middle-tray-actions {
    .exit-button {
      padding: 0px 14px;
      margin-left: 0;
      width: 46px;
    }

    .settings-tray-button {
      margin-left: 0;
    }

    .idle-message {
      width: 108px;
      .tippy-content {
        white-space: normal;
      }
    }

    .tippy-content {
      white-space: nowrap;
    }
  }

  .collapsed-left-tray-actions,
  .collapsed-middle-tray-actions {
    .chat-button {
      width: 40px;
    }
    .tray-button {
      width: 25px;
    }
  }

  .collapsed-right-tray-actions {
    .tippy-content {
      white-space: nowrap;
    }
  }

  .collapsed-middle-tray-actions,
  .collapsed-right-tray-actions {
    .extra-menu-button {
      padding: 0;
    }
  }

  .collapse-divider {
    border-bottom: 1px solid rgb(212, 212, 212);
    margin-top: 10px;
    margin-bottom: 10px;
    ~ .collapse-divider {
      margin-top: 18px;
    }
  }
}

.debug-button {
  width: 15px;
  background-color: rgba(255, 255, 255, 0.01);
  height: 15px;
  user-select: none;
}

.debug-spacer {
  width: 29px;
}

.flex-spacer {
  flex-grow: 1;
}

.debug-offset {
  width: 44px;
}

.alignment-offset {
  width: 102px;
}

.chat-tab {
  .new-message-count {
    display: inline-block;
    padding: 2px 8px;
    margin: 8px;
    margin-left: 10px;
    background-color: $sbcarribean;
    border-radius: 3px;
    height: 28px;
  }
}

.broadcast-tippy {
}

.more-button {
  font-size: 24px;
}

.extra-menu-button {
  padding-left: 0;
  min-width: 39px;
}

.more-actions-menu {
  padding: 3px 8px;

  li:not(:last-child) {
    margin-bottom: 4px;
  }
}

.refresh-button {
  padding-right: 4px;
}

//for the old app
.main-content {
  .tray {
    &:not(.side-menu-hidden) {
      @media screen and (max-width: 1084px) {
        .alignment-offset {
          width: clamp(1px, 1vw, 102px);
        }

        .debug-spacer {
          width: clamp(1px, 1vw, 29px);
        }

        .flex-spacer ~ .flex-spacer {
          flex-grow: 0;
        }
      }

      @media screen and (max-width: 970px) {
        .flex-spacer ~ .flex-spacer {
          flex-grow: 1;
        }
      }
    }

    &.side-menu-hidden {
      @media screen and (max-width: 783px) {
        .alignment-offset {
          width: clamp(1px, 1vw, 102px);
        }

        .debug-spacer {
          width: clamp(1px, 1vw, 29px);
        }

        .flex-spacer ~ .flex-spacer {
          flex-grow: 0;
        }
      }

      @media screen and (max-width: 670px) {
        .flex-spacer ~ .flex-spacer {
          flex-grow: 1;
        }
      }
    }
  }
}
