.sliders-container {
  width: 20px;

  .color-input-container {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: none;
    background: none;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0px 1px 3px 0px #5666d7;

    .color-input {
      width: 35px;
      height: 35px;
      border: none;
      background: none;
      margin-left: -7px;
      margin-top: -7px;
    }
  }

  .dimensions-input {
    -webkit-appearance: slider-vertical;
    width: 10px;
  }

  @-moz-document url-prefix() {
    .dimensions-input {
      width: 100px;
      height: 110px;
      border: 0;
      margin-left: -40px;

      -moz-transform: rotate(270deg);
    }
  }
}

.tile-settings-container {
  .button {
    svg {
      transform: scale(0.9);
    }
  }
}
