.event-usage {
  display: flex;
  flex-flow: row nowrap;
}

.event-usage__column {
  padding: 0.5rem 1.4rem 0.5rem 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
}


.event-usage__column--time {
  min-width: 170px;
  max-width: 170px;

  > span {
    margin-left: 6px;
  }
}

.event-usage__column--name {
  min-width: 220px;
  max-width: 220px;
}

.event-usage__column--progress {
  min-width: 280px;
  max-width: 280px;
}

.event-usage__column--link {
  flex: 1 0 0;
  padding-right: 0;
}
