.ended-event {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.title-row--EndedEventScreen {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}
.title-image--EndedEventScreen {
  display: inline-block;
  flex-grow: 1;
  padding: 1rem;
  svg {
    width: 188px;
  }
}
