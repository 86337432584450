#host-dashboard {
  background-color: white;
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: 1fr;
  min-height: 100%;
  height: 100vh;
  width: 100vw;

  .side-menu {
    background-color: #ececec;
    // padding: 1rem;

    .menu {
      height: 100%;
      display: flex;
      flex-direction: column;
      // 46 px = 30px logo + 16px top padding
      max-height: calc(100vh - 46px);

      li a {
        padding-left: 1rem;
      }

      .menu-list {
        height: 100%;
        display: flex;
        flex-direction: column;

        li:last-child {
          margin-top: auto;
        }

        padding-bottom: 30px;
      }
    }
  }

  .primary-content {
    padding: 2rem;
    padding-top: 1rem;
    overflow-y: scroll;
    background-color: #f7f7f7;
  }

  .title.is-1 {
    font-weight: 600;
    font-size: 2rem;
  }
}
