.billing-page {
  max-width: 900px;

  .billing-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
      font-size: 20px;
    }
  }
}