@import "../../styles/colors.scss";
.participant-caret-tippy {
  > .tippy-content {
  }
}

.participant-actions-menu {
  text-align: left;
  font-size: 14px;
  min-width: 130px;
  display: flex;
  justify-content: center;
  user-select: none;
  padding: 4px 3px;

  svg {
    font-size: 24px;
    width: 24px;
    margin-right: 8px;
    vertical-align: middle;
  }

  ul {
    width: 100%;
  }

  li {
    cursor: pointer;
    color: #363636;

    &:hover {
      background-color: #f5f5f5;
    }

    div {
      padding: 10px 6px;
    }
  }

  li > a {
    padding: 10px 6px;
  }

  li.host-only-option {
    & > a {
      color: darken($sbmediumpurple, 20%);
      background-color: #f8f1ff;
      border-radius: 3px;
      margin-top: 5px;
      margin-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;

      &:hover {
        background-color: darken(#f8f1ff, 2%) !important;
      }
    }
  }

  .bottom-align-icon {
    svg {
      vertical-align: bottom;
    }
  }
}

.dropdown {
  z-index: 10;
}

.reaction-option {
  vertical-align: middle;
}

.button {
  &.is-text-clear-bg {
    color: white;
    text-decoration: none;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused {
      background-color: transparent;
      color: white;
    }

    &:active,
    &.is-active {
      background-color: transparent;
      color: white;
    }

    &:focus,
    &.is-focused {
      border-color: none;
      color: white;
      &:not(:active) {
        box-shadow: none;
      }
    }
  }
}
