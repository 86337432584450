@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "../../styles/colors.scss";

.tooltip-content > p {
  text-align: center;
}

.no-break {
  white-space: nowrap;
  position: relative;
}

.lobby-name-row {
  .level {
    align-items: start;
    margin-bottom: 0;

    & > * {
      min-width: 20%;
    }

    .level-left {
      margin-top: 1px;
      flex-shrink: initial;

      &:hover {
        .pen-icon,
        .trash-icon,
        .permanent-icon {
          display: inline-block !important;
        }
      }

      .level-item {
        // display: block;
        flex: none;
        flex-shrink: 1;
      }
    }

    .level-right {
      .level-item {
        height: 20px;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  .floating-actions {
    // display: flex;
    // height: 26px;
    background-color: white;
    padding: 2px 6px 2px 2px;
    margin-top: -0.13rem;
    border-radius: 20px;
  }

  .pencil-icon {
    flex-shrink: 0;
    margin-left: 0;
    position: absolute;
    top: 0;
    left: 0;

    .floating-actions {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
    }

    &.invisible-icon {
      display: none;
      svg {
        display: inline-block;
      }
    }
  }

  .pen-icon,
  .trash-icon,
  .permanent-icon,
  .locked-icon {
    display: inline-block;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
      color: #a7a7a7;
      vertical-align: initial;

      &:hover {
        color: $blue;
      }
    }
  }

  .pen-icon,
  .trash-icon,
  .permanent-icon {
    display: none !important;
    margin-left: 4px;
  }

  .pen-icon {
    width: 0.8rem;
    cursor: pointer;
  }

  .pencil-icon {
    width: 0;
  }

  .trash-icon {
    width: 0.8rem;
  }

  .locked-icon {
    width: 12px;
    margin-bottom: 2px;
    margin-right: 2px;
  }

  .permanent-icon {
    margin-left: 6px;
    padding-top: 0px;

    &.red {
      width: 17px;
      svg {
        color: $error-red;
      }

      &:hover {
        svg {
          color: darken($error-red, 20%);
        }
      }
    }

    &.green {
      width: 18px;

      svg {
        color: $sbcarribean;
      }

      &:hover {
        svg {
          color: darken($sbcarribean, 5%);
        }
      }
    }
  }

  .room-action-loader {
    @extend %loader;
    margin-left: 5px;
    font-size: 13px;
    display: inline-block;
    display: none;

    &.active {
      display: inline-block;
    }
  }

  .room-name {
    line-height: normal;
    cursor: text;
    display: inline-block;
    // padding-top: 0.5rem;
    // padding-bottom: 0.5rem;
    word-break: break-word;
    overflow-wrap: anywhere;
    width: 12rem;
    padding-right: 0;
    font-size: 17px;

    .editable-name {
      display: inline;
      outline: none;
      margin-right: 0.1rem;
      line-height: 1rem;
      padding: 0 0.13rem;

      &:focus {
        background-color: lemonchiffon;
        border-radius: 5px;
      }
    }

    &:focus-within {
      // padding-left: 0.5rem;
      // padding-right: 0.5rem;

      // margin-right: 0.5rem;
    }
  }

  .room-name[disabled] {
    cursor: default;
  }

  .podium-icon-wrapper {
    display: inline-block;
    cursor: pointer;
    margin-right: 2px;
    vertical-align: top;

    .podium-icon {
      background: $sbcarribean;
      color: white;
      font-size: 18px;
      padding: 3px;
      margin-right: 3px;
      border-radius: 3px;
    }
  }

  .lock-toggle-container {
    height: 20px;
    // position: absolute;
    // margin-right: 3px;
    // margin-top: 45px;
    z-index: 1;
  }
}

.thrifty-tag {
  display: inline-block;
  border-radius: 3px;
  background-color: aquamarine;
  font-size: 12px;
  padding: 1px 4px;
  margin-left: 5px;
}

.auditorium-row {
  .floating-actions {
    background-color: #f5f5f5;
  }
}

.more-participants-container {
  .more-participants-container-2 {
    cursor: pointer;
    position: relative;
    display: inline-block;
    .tippy-box {
      min-width: 280px;
      padding: 8px;
      padding-bottom: 1px;
    }

    .participant-caret-tippy {
      min-width: 200px;
    }
  }

  .more-participants {
    margin-top: 0.25rem;
    font-size: 0.8rem;
    color: #777;
    display: inline-block;

    svg {
      visibility: hidden;
      vertical-align: top;
    }
    &:hover {
      color: #333;
      svg {
        visibility: visible;
      }
    }
  }

  .tippy-content {
    max-height: 50vh;
    padding-bottom: 0.8rem;
    overflow-y: auto;
  }
}

//placeholder for contentEditable

// Chrome
[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  color: #aaa;
  display: inline-block;
  padding: 0 0.13rem;
}

/* Mozilla Firefox */
@-moz-document url-prefix() {
  [contentEditable="true"]:empty {
    content: attr(placeholder);
    color: #aaa;
    display: inline-block;
    padding: 0 0.13rem;
  }

  .lobby-name-row {
    .room-name {
      display: flex;
    }

    .floating-actions {
      margin-top: -0.25rem;
    }
  }
}
