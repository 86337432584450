@import "../../../styles/colors";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";

.team-management {
  .url-col {
    width: 50%;
  }

  .invite-teammate-button {
    margin-bottom: 1rem;
  }

  .no-sessions {
    margin-top: 8px;
    padding: 2px 8px;
    color: grey;
  }

  .invitation-failures {
    box-shadow: none;
    border: 3px solid #f5d400;
    background-color: #fcf6cf;
    .actions {
      text-align: right;
    }
  }
}

.tippy-box.blue-tippy {
  background-color: $sbmustard;
  color: black;
}

.team-status--invited {
  padding: 0.7em 1em 0.7em 1.7em;
  color: #888;
  cursor: not-allowed;
}

.team-table {
  td {
    vertical-align: middle;
  }

  th.team-table__column-header {
    text-align: left;
  }

  th.team-table__status-header {
    padding-left: 2.1em;
    width: 200px;
    text-align: left;
  }

  tbody {
    td {
      padding: 0 0.5em;
    }
  }
}

.office-invite-modal {
  label {
    font-weight: normal;
  }
}

.status-cell {
  display: flex;
  align-items: center;
}
