.accept-invite-form {
  padding: 2rem 3rem 1rem 3rem;
  .input[disabled] {
    background-color: #eeeeee;
    border-color: #dbdbdb;
  }

  .i-agree-field {
    margin-top: 1rem;
  }

  .i-agree-content {
    display: flex;
    font-size: 0.9em;

    > input {
      margin-top: 0.2rem;
      margin-right: 0.5rem;
    }
  }

  .i-agree-text {
    display: inline-block;
  }

  .get-started-button {
    width: 100%;
    margin-top: 1rem;
  }
}
