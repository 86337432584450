.usage-summary {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  position: relative;

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1000px;

    .subscription-actions {
      button {
        margin-left: 15px;
      }
    }
  }
}

.main-value {
  font-size: 40px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  padding-bottom: 8px;
}

.main-value__loader-wrap {
  max-height: 30px;
  max-width: 30px;

  .loader,
  .loader::after {
    max-height: 30px;
    max-width: 30px;
  }
}

.main-value--label {
  font-size: 18px;
  font-weight: 500;
}

.info-block {
  border-radius: 10px;
  padding: 1.5rem 2rem;
}

.info-block--full-width {
  max-width: 1000px;
  min-width: 1000px;
}

.mb10 {
  margin-bottom: 1.8rem;
}

.info-block__title {
  font-weight: bold;
  margin-bottom: 1rem;
  flex: 1 0 0;
}

.info-block__footer-title {
  font-weight: bold;
  flex: 1 0 0;
  display: flex;
  align-items: center;

  > span {
    margin-left: 6px;
  }
}

.page-title {
  margin-bottom: 2rem;
  font-weight: bold;
  font-size: 24px;
}

.info-block__title {
  font-size: 16px;
  font-weight: bold;
}

.info-block__header {
  display: flex;
  flex-flow: row;
}

.info-block__footer {
  display: flex;
  flex-flow: row;
  padding-top: 1rem;
}

.usage-summary__page-footer {
  font-size: 18px;
  font-weight: bold;
  padding: 1rem 0;

  > div {
    padding: 0.2rem 0;
  }
}

.total-usage-row {
  > * {
    display: inline-block;
  }

  &__waiting {
    padding-left: 1rem;
    height: 100%;
    vertical-align: top;
    margin-top: 86px;
    color: grey;
    .loader {
    }
  }

  &__waiting-loader {
    display: inline-block;
  }
}

.main-value__number {
  line-height: 1;
}

.main-value__label {
  color: #737473;
  margin-left: 2px;
  margin-right: 8px;
}

.info-block__footer-title__title {
  color: #737473;
}

.minutes-spacer {
  margin-left: 0.5rem;
  display: inline;
}
