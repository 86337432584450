@import "../../../styles/colors";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";

.create-call-modal {
  .create-call-loading {
    display: inline-block;
    padding-left: 5px;
    padding-top: 10px;
    &::after {
      @extend %loader;
      display: inline-block;
    }
  }

  .trial-message {
    margin-bottom: 12px;
    color: #ec191d;
  }
}
