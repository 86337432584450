.call-row {
  .created-time-ago {
    font-size: 0.8em;
    height: 30px;
    display: flex;
    align-items: center;
  }

  td {
    height: 30px;
    vertical-align: middle;
  }
}