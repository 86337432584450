.dm-chat-portal {
  .tiny-profile-photo-container {
    img {
      width: 56px;
      height: 56px;
    }

    div {
      width: 52px;
      height: 52px;
      line-height: 54px;
      font-size: 22px;
    }
  }
}

.user-chat-brief-info-container {
  margin-top: -3px;
  font-weight: 400;

  .user-chat-title {
    font-weight: 600;
    font-size: 16px;
    display: flex;

    div {
      max-width: 160px;
      text-overflow: ellipsis;
      overflow: hidden;

      &.is-not-my-name {
        max-width: 190px;
      }
    }

    .you-label {
      font-weight: 500;
      margin-top: 2px;
    }
  }

  .status-or-local-time-container {
    display: flex;
    align-items: center;

    svg {
      width: 11px;
      margin-left: -0.3px;
      height: 11px;
    }

    .status-or-local-time-text {
      font-size: 12px;
      margin-left: 6.2px;

      div {
        display: flex;
        font-weight: 300;
      }
    }
  }
}

.user-chat-actions {
  .user-participant-actions {
    height: 21.5px;
    margin: 10px 0;
  }
  .user-participant-actions:empty {
    height: auto;
    margin: 0;
  }
}

.in-office-chat {
  font-size: 17px;
  font-weight: 500;
  padding-top: 4px;
}
