.form-component {
  margin-bottom: 24px;
  position: relative;

  &__input {
    box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, .05);
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding: calc(.5em - 1px) calc(.75em - 1px);
    position: relative;
    vertical-align: top;
    background-color: #fff;
    border-top-color: rgb(219, 219, 219);
    border-right-color: rgb(219, 219, 219);
    border-bottom-color: rgb(219, 219, 219);
    border-left-color: rgb(219, 219, 219);
    color: #363636;
    height: 36px;
    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-color: #cbd4db;
    border-radius: 6px;
    padding: 0 12px;
    box-sizing: border-box;
  }

  &__error {
    position: absolute;
    top: 100%;
    left: 0;
    color: red;
    font-size: 12px;
  }
}