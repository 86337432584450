.change-logo {
  .change-logo-container {
    margin-top: 2em;
  }
  .change-photo {
    .file {
      display: block;
    }
  }
  .current-photo {
    img {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }
}
