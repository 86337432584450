.todo-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.reaction-display {
  pointer-events: auto;

  .invite-display-box {
    padding: 0 !important;
    background-color: rgba(255, 255, 255, 0.95) !important;
    margin-bottom: 8px;

    .reaction-title {
      padding: 1.8rem 2rem;
      margin-bottom: 0;
      font-size: 14px;
    }

    .invite-actions {
      display: flex;

      .button {
        flex-grow: 1;
        font-size: 14px;
      }
    }
  }
}

#message-notification-container {
  position: absolute;
  z-index: 1;
  left: 0.5rem;
  bottom: 0.5rem;
  width: 300px;

  &.hide-message-notifications {
    display: none;
  }

  .message-notification-list {
    .reaction-box {
      padding: 5px;
    }

    .box {
      padding: 10px 0;
    }
  }
}

.reaction-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  .reaction-display {
    z-index: 2;
    .reaction-box {
      display: inline-flex;
      margin-right: 15px;
      flex-direction: column;
      align-items: center;

      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
        0 0 0 1px rgba(10, 10, 10, 0.02);
      color: #4a4a4a;
      padding: 1.25rem;

      button.button {
        margin-bottom: 0 !important;
      }

      svg {
        width: 100px;
        display: block;
      }
    }
  }
}

.reaction-text {
  text-align: center;
  color: black;
  font-size: 12px;

  .reaction-title {
    font-size: 16px;
  }

  .reaction-from {
    margin-top: 10px;
  }
}

.reaction-item-enter {
  opacity: 0;
}
.reaction-item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.reaction-item-exit {
  opacity: 1;
}
.reaction-item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.message-notification-item-enter {
  opacity: 0;
}
.message-notification-item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.message-notification-item-exit {
  opacity: 1;
}
.message-notification-item-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}
