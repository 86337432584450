$blue: #407ef6;
$grey: #6f6f6f;

$blueBackground: rgba(64, 125, 246, 0.1);

$spaceOutline: #efefef;

// color scheme
$sbred: #f00e03;
$sbmustard: #ffdd56;
$sbcarribean: #00d1b2;
$sbroyalblue: #3273dc;
$sbportage: #7ea5e7;
$sblinkwater: #dbe3f7;
$sbmediumpurple: #be79e6;

// derivative
$sbsteelgrey: #485161;

$selectedgold: #fff292;
$selectedgold2: #ffe373;
$selectedgold3: #ffeb9a;
$hostpurple: #ebd6f7;

$success-green: #00c851;
$warning-yellow: #ffbb33;
$error-red: #ff4444;

$lgreen: #00d182;
$yet-another-blue: #2774e0;
$here-background: #e7e7e7;
$here-tag-hover: #f5f5f5;

$custom-colors: (
  "sred": #ec181c,
  "lightblue": #a2c3ff,
  "lightblue3": #edf3fc,
  "lighterblue2": #b3ceff,
  "lighterblue1": #c3d8ff,
  "lighterblue": #d4e3ff,
  "lightererblue": #e6eeff,
  "carribean": $sbcarribean,
  "sblue": $sbroyalblue,
  "darkcarribean": #00a88f,
  "lgreen": #00d182,
  "buttongrey": #eaeaea,
  "spurple": $sbmediumpurple,
);

$foyer-box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 8px;
$foyer-box-shadow-hover: rgba(0, 0, 0, 0.27) 1px 1px 6px;

// $family-primary: 'Source Sans Pro', 'Segoe UI', 'Roboto', 'Oxygen',
//   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

.button .is-compact,
.tag .is-compact {
  height: 1.8em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.button:disabled {
  pointer-events: none !important;
}

.button.is-host.is-outlined {
  background-color: transparent;
  border-color: $sbmediumpurple;
  color: $sbmediumpurple;

  &:hover {
    background-color: $sbmediumpurple;
    color: white;
  }
}

.tippy-box[data-theme~="install"] {
  color: white;
  background: rgb(0, 116, 224);
  background: linear-gradient(
    315deg,
    rgba(0, 116, 224, 1) 0%,
    rgba(67, 154, 255, 1) 100%
  );
}
