.office-foyer__card {
  &__wrapper {
    display: flex;
    flex-direction: column;

    .nobody-message {
      padding: 2rem 4rem;
      text-align: center;
    }
  }

  &__title {
    &__wrapper {
      flex-direction: row;
      width: 100%;

      .room-name {
        font-style: normal;
        font-weight: bold;
        font-size: 1rem;
        line-height: 18px;
        color: #222222;
        width: inherit;
        padding-right: 0.25rem;
      }

      .join-room-button {
        font-size: 0.75em;
        height: 2em;
        padding-left: 1em;
        padding-right: 1em;
      }

      .level {
        align-items: flex-start;
      }

      .editable-name {
        font-size: 1rem;
      }
    }
  }

  &__avatars__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    margin-top: 1em;
    margin-left: -0.25em;
    margin-right: -0.25em;
  }

  &__avatar_and_name_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    display: inline-flex;
    border-radius: 50%;
    margin-bottom: 4px;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &__wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      overflow: hidden;
      flex-basis: 25%;
      align-items: center;
    }

    &__img {
      cursor: pointer;
      height: 100%;
      width: 100%;
    }

    &__initials {
      font-weight: 500;
      font-size: 1.5em;
      cursor: pointer;
      color: #fff;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }

    &__name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      max-width: 75%;
      font-style: normal;
      font-weight: normal;
      font-size: 0.733333333em;
      line-height: 16px;
      color: #444444;
    }

    &__count {
      font-size: 1rem;
    }
  }
}
