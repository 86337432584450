@charset "utf-8";

// @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');

@import "styles/colors.scss";
@import "~bulma/bulma.sass";
@import "~react-tippy/dist/tippy.css";
@import "~react-toastify/dist/ReactToastify.css";
@import "styles/toastify-overrides.scss";
$divider-margin-inner-size: 0px;
$divider-margin-size: 8px;
$divider-background-color: #ededed;
@import "@creativebulma/bulma-divider";

html {
  height: 100%;
  overflow-y: auto !important;
  font-size: 15px !important;
  @media (max-width: 1100px) {
    font-size: 14px !important;
  }
  @media (max-width: 900px) {
    font-size: 13px !important;
  }
}

body {
  margin: 0;
  font-family: $family-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;

  & > * {
    height: 100%;
  }

  .impersonating-banner {
    height: auto;
    width: 100%;
    background-color: black;
    border-bottom: 5px dashed red;
    padding: 5px 0;
    text-align: center;
    margin: auto;

    &--message {
      background-color: black;
      color: white;
      width: fit-content;
      margin: auto;
      padding: 0 5px;
      border-radius: 3px;
      display: inline-block;
    }

    .logout-button {
      margin-left: 10px;
      padding: 2px 12px;
      height: auto;
      display: inline-block;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fullscreen-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.is-size-75 {
  font-size: 0.9rem !important;
}
