@import "../../../styles/colors";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";

.network-stats__wrapper {
  padding: 10px 14px 14px 14px;
  min-width: 262px;

  h1 {
    font-size: 150%;
  }

  .subtle {
    color: #777;
  }
}

.tray {
  .networkstats-button {
    font-size: 18px;
    color: white;

    &--low {
      color: $warning-yellow !important;
    }

    &--very-low {
      color: $error-red !important;
    }
  }
}

.network-stats__row {
  border: 1px solid #b9b9b9;
  padding: 1em;
  border-radius: 4px;

  svg {
    font-size: 16px;
    color: #999;
    margin-left: 2px;
    cursor: help;
  }
}

.network-stats__quality {
  margin-top: 10px;
}

.network-stats__quality-tag {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 5px;
  color: white;
  background-color: #aaa;
  margin-left: 5px;

  &--good {
    background-color: $success-green;
  }

  &--low {
    background-color: $warning-yellow;
    color: black;
  }

  &--very-low {
    background-color: $error-red;
  }
}

.network-stats__measuring {
  display: inline-block;
  color: #999;
  font-style: italic;
  margin-left: 2px;
}

.network-stats__download {
  margin-top: 10px;
}

.network-stats__upload {
  margin-top: 10px;
}

.network-stats__statistic {
  font-size: 150%;
  font-weight: 500;

  .network-stats__units {
    font-size: 1rem;
  }
}

.network-stats__waiting {
  margin-top: 10px;
  color: #777;

  .loader {
    @extend %loader;
    display: inline-block;
    margin-right: 8px;
  }
}

.network-stats__clarification {
  margin-top: 10px;
  color: #777;
  font-style: italic;
}

.network-stats__alone {
  margin-top: 10px;
  color: #777;
  font-style: italic;
}