@import "../../styles/colors.scss";

.StatusDropdown__menu {
  text-align: left;
  font-size: 14px;
  min-width: 130px;
  display: flex;
  justify-content: center;;

  ul {
    width: 100%;
  }

  li {
    cursor: pointer;
    color: #363636;

    &:hover {
      background-color: #F5F5F5;
    }

    div {
      padding: 10px 6px;
    }
  }

  li > a {
    padding: 10px 6px;
  }

  li.host-only-option {
    & > a {
      color: darken($sbmediumpurple, 20%);
      background-color: #f8f1ff;
      border-radius: 3px;
      margin-top: 5px;
      margin-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;

      &:hover {
        background-color: darken(#f8f1ff, 2%) !important;
      }
    }
  }
}

.dropdown {
  z-index: 10;
}


.button {
  &.is-text-clear-bg {
    color: white;
    text-decoration: none;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused {
      background-color: transparent;
      color: white;
    }

    &:active,
    &.is-active {
      background-color: transparent;
      color: white;
    }

    &:focus,
    &.is-focused {
      border-color: none;
      color: white;
      &:not(:active) {
        box-shadow: none;
      }
    }
  }
}


.teammember-role-content {
  display: flex;
  align-items: center;
  padding: 0 8px;
  max-width: 112px;
  min-width: 112px;

  span {
    height: 26px;
    margin-right: 5px;
  }

  div {
    display: flex;
  }
}
