.knock-cancel-button {
  color: inherit;
  color: #aaa !important;
  font-size: 0.8rem;
  margin-right: -0.4rem;

  &:hover {
    color: inherit;
    color: #888 !important;
    background-color: transparent !important;
  }
}
