@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "../../styles/colors.scss";

.forgot-password-form {
  .reset-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;

    svg {
      font-size: 24px;
      color: $sbcarribean;
      position: absolute;
      margin-left: 10px;
    }
  }

  .reset-loading {
    display: inline-block;
    padding-top: 10px;
    padding-left: 14px;
    &::after {
      @extend %loader;
      display: inline-block;
    }
  }
}

.text-form-padding {
  margin-top: 20px;
  margin-bottom: 25px;
}
