.StatusDropdownItem {
  text-align: left;
  padding: 4px 8px;
  display: flex;
  align-items: stretch;
  cursor: pointer;

  &:hover {
    background: #f7f5f5;
  }
}

.StatusDropdownItem__label {
  font-weight: bold;
  font-size: 16px;
}


.StatusDropdownItem__description {
  font-size: 14px;
}

.StatusDropdownItem__icon {


 max-width: 21px;
 min-width: 21px;
 min-height: 21px;
 max-height: 21px;
}

.StatusDropdownItem__icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
