@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "../../styles/colors.scss";

.rooms-menu .join-room-button,
.lobby-name-row .join-room-button,
.user-participant-actions .join-room-button,
.people-row .join-room-button,
.user-meeting-row .join-room-button {
  vertical-align: text-bottom;
  height: 1.9em;
  padding-right: 1em;
  padding-left: 1em;
  line-height: 0;
}

.button.full-room {
  color: grey !important;
  border-color: grey !important;
}

.joining-button {
  line-height: 12px;
  &::after {
    @extend %loader;
    display: inline-flex;
  }
}

.here-indicator {
  color: #777;
  margin-left: 5px;
  margin-right: 0.4rem;
  margin-top: 2px;
  font-size: 13px;
  text-transform: uppercase;
}
