.plain-grey-page {
  .content-column {
    text-align: left;
    margin: auto;
    width: 900px;
    max-width: 900px;
    padding: 30px;
    padding-bottom: 60px;

    &.narrow {
      width: 600px;
      max-width: 600px;
    }
  }

  .content-column label {
    padding-right: 0.5em;
  }

  .message {
    background-color: white;
  }

  .login-modal-padding {
    padding: 0;
    margin-bottom: 1rem;
  }
}
