@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "../../styles/colors.scss";

.circle-background {
  background: #111111;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  .circle-container {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
  }

  .tile-bottom-center {
    button {
      padding: 0;
      font-size: clamp(12px, 2vh, 18px);
      min-width: 70px;
      max-width: 150px;
      width: 10vh;
    }
  }

  .tile-name-tag-content {
    display: flex;
    position: absolute;
    align-items: center;

    span {
      margin-right: 5px;
      max-width: clamp(84px, 12vh, 180px);
      text-overflow: ellipsis;
      overflow: hidden;
    }

    div {
      display: flex;
    }
  }

  &.with-many-people {
    .circle-container {
      margin: 12px 30px 0 30px;
      justify-content: flex-start;
      height: fit-content;
    }
  }

  &.with-large-tiles {
    height: clamp(126px, 18vh, 270px);

    .circle-container {
      margin: 0px 20px;
      justify-content: flex-start;
      height: fit-content;
      margin-top: clamp(6.3px, 0.9vh, 13.5px);
    }

    .circle {
      margin: 0 clamp(10px, 1.5vh, 23px);
      margin-top: clamp(10px, 1.5vh, 23px) !important;
    }

    .room-reactions-me,
    .room-reactions-others {
      font-size: 40px;
      top: 25%;
    }
  }

  &.with-many-people {
    justify-content: flex-start;
  }

  .room-reactions-me,
  .room-reactions-others {
    position: absolute;
    left: 70%;
    top: -15%;
    font-size: clamp(30px, 6.5vh, 60px);
    width: 1px;
  }

  .mic-mute-indicator {
    position: absolute;
    right: -2px;
    bottom: -2px;
    font-size: clamp(14px, 2vh, 30px);
    background-color: rgb(251, 85, 84);
    color: rgb(255, 255, 255);
    border-radius: 50%;
    height: 4vh;
    width: 4vh;
    min-width: 28px;
    max-width: 60px;
    min-height: 28px;
    max-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
}

.circle {
  margin: 1vh clamp(10px, 1.5vh, 23px);
  display: flex;
  position: relative;
}

.circle .cam-muted {
  position: relative;
  margin-bottom: 5px;
  height: 10vh;
  min-height: 70px;
  max-height: 150px;

  &__avatar,
  &__initials {
    min-height: 70px;
    min-width: 70px;
    max-height: 150px;
    max-width: 150px;
    height: 10vh;
    width: 10vh;
    border-radius: 50%;
    box-shadow: 0 0 0px 2px #fff;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    font-size: clamp(35px, 5vh, 75px);
    font-weight: bold;
    color: #fff;
  }
}

.circle-item {
  margin: 0 20px;
  // position: relative;
}

.circle.active-speaker {
  // position: relative;

  .cam-muted {
    &__avatar,
    &__initials {
      box-shadow: 0 0 0px 3px #00d182;
      &.mic-muted {
        box-shadow: 0 0 0px 2px #fff;
      }
    }
  }
}

.break-circles {
  flex-basis: 100%;
  height: 5px;
}
