#host-dashboard {
  .trial-banner {
    font-size: 14px;
    font-weight: bold;
    padding-top: 14px;
    padding-bottom: 14px;

    a {
      vertical-align: baseline;
      font-size: 14px;
    }

    .post-padded {
      padding-right: 1em;
    }
  }
}
