@import "../../styles/colors.scss";

.side-menu__header {
  .office-menu__head-title {
    height: 3rem;
    padding-left: 0.75rem;
    padding-right: 0.25rem;
    border-bottom: 1px solid #d0d0d0;
    display: flex;
    align-items: center;

    .head-left {
      display: inline-block;
      flex-grow: 1;
      line-height: 28px !important;
      // height: 2em !important;
      vertical-align: middle;

      > h1.head-title {
        font-weight: bold;
        color: #222;
        display: inline-block;
        line-height: 1em !important;
        height: 1em !important;
        width: 13.5rem;
        word-break: break-word;
      }

      img.organization-logo {
        display: inline-block;
        width: 28px;
        height: 28px;
        vertical-align: top;
        margin-right: 0.375em;
        border-radius: 0.25em;
        border: none;
        border-width: 0;
        border-color: transparent;
        // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      }
    }

    .head-right {
      display: flex;
      align-items: center;
      flex-grow: 0;
      display: flex;
      font-size: 1.125rem;
      color: #989898;

      .user-settings-trigger,
      .collapse-sidebar {
        cursor: pointer;
        padding-top: 1rem;
        padding-bottom: 1rem;

        &:hover {
          color: #333;
        }
      }

      .user-settings-trigger {
        padding-left: 0.75rem;
        padding-right: 0.375rem;
      }

      .collapse-sidebar {
        padding-left: 0.375rem;
        padding-right: 0.75rem;
      }
    }
  }

  .user-menu-tippy {
    padding: 0.5rem 0.3rem 0.5rem 0.3rem;

    .tippy-content {
      padding: 0.333rem;
    }
  }

  .install-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  .install-button {
    align-self: center;
    height: 2.25em;

    background: rgb(0, 116, 224);
    background: linear-gradient(
      315deg,
      rgba(0, 116, 224, 1) 0%,
      rgba(67, 154, 255, 1) 100%
    );

    &:hover {
      background: rgb(1, 96, 217);
      background: linear-gradient(
        315deg,
        rgba(1, 96, 217, 1) 0%,
        rgba(79, 143, 252, 1) 100%
      );
    }
  }
}

.install-tooltip {
  max-width: 15em;
  padding: 0.75em;
}

.settings-menu {
  hr {
    margin: 1rem 0;
  }
}

.in-dropdown-menu-icons {
  margin-right: 5px;
  height: 10px;
  margin-bottom: 2px;
}

.availability-status {
  li {
    &:nth-child(2) > a > svg {
      margin-right: 0.2em;
      margin-left: -0.1em;
    }
  }

  .door-is-open-action {
    svg {
      color: $sbroyalblue;
    }
  }
}

.my-settings {
  padding: 1.23rem 1.23rem 0 1.05rem;

  li > a {
    margin-left: -1rem;
    margin-right: -1.2rem;
    text-align: left;
    padding-left: 2rem;
  }

  .menu-label {
    width: 9.375rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
