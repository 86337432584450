@import "../../../styles/colors.scss";

.office-onboarding-join-spaces {
  padding: 2rem 3rem 0 3rem;

  .join-spaces-scroller {
    max-height: calc(100vh - 27rem);
  }

  .actions {
    margin-top: 2rem;
  }
}

.office-onboarding-request-notifications {
  padding: 2rem 3rem 0rem 3rem;

  .actions {
    .button {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.join-description {
  text-align: center;
  margin-bottom: 2rem;
}

.request-description {
  text-align: center;
  margin-bottom: 2rem;

  h2 {
    margin-bottom: 1rem;
  }
}

.office-onboarding-request-notifications,
.office-onboarding-profile-photo,
.office-onboarding-slack-user {
  .skip-button {
    position: absolute;
    right: 1rem;
    bottom: 1rem;

    .button {
      font-size: 1rem;

      color: #555;
    }
  }
}

.office-onboarding-request-notifications {
  .checking-loader {
    font-size: 1.8rem;
  }

  .notifications-success {
    display: flex;
    align-items: center;
    font-size: 2em;
    color: $lgreen;

    .loader {
      margin-left: 0.5rem;
      font-size: 1.8rem;
    }
  }

  .skip-tippy {
    padding: 0.5em;
    .tippy-actions {
      padding-top: 0.5em;
      .button:last-child {
        margin-left: 1px;
      }
    }
  }
}

.office-onboarding-profile-photo,
.office-onboarding-launch-the-app {
  margin-top: 1rem;
  .content {
    text-align: center;
    h2 {
      margin-bottom: 2rem;
    }
  }

  .change-photo {
    align-items: center;
  }

  .actions {
    margin-top: 2rem;
    .button {
      margin-top: 0.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    .button.with-pwa-support {
      margin-bottom: 0.5rem;
    }
    a.with-pwa-support {
      color: rgb(85, 85, 85);
      text-decoration: none;
      font-size: 0.7rem;
    }
    a.with-pwa-support:hover {
      text-decoration: underline;
    }
  }
}

.onboarding-button {
  font-size: 1.1rem;
}
