.join-spaces-modal {
  margin: 2rem 4rem 1.5rem 4rem;
  > p {
    text-align: center;
  }

  .modal-title {
    display: flex;
    justify-content: center;
  }
  .change-photo-container {
    position: relative;
    padding: 1rem 6rem;
  }
  .join-spaces-actions {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
  }
}
