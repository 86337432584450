@import "../../styles/colors.scss";
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";

#name-input-container {
  text-align: left;
  margin: auto;
  width: 900px;
  max-width: 900px;
  padding: 30px;
  padding-bottom: 60px;
  padding-top: 15px;

  .notification {
    margin-top: -15px;
    background-color: $sbmustard;
  }
}

#name-input-container label {
  padding-right: 0.5em;
}

.foyer {
  opacity: 0;
  background-color: #f5f5f5;

  &.visible-foyer {
    opacity: 1 !important;
  }

  &.foyer-transition-enter {
    opacity: 0;
  }

  &.foyer-transition-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }

  &.foyer-transition-enter-done {
    opacity: 1;
  }

  &.foyer-transition-exit {
    opacity: 1;
  }

  &.foyer-transition-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }

  .upload-image-row {
    float: right;

    button {
      font-size: 14px;
    }

    svg {
      font-size: 18px;
      margin-right: 6px;
    }
  }

  .banner {
    width: 100%;
    margin-bottom: 20px;
    position: relative;

    img {
      border-radius: 5px;
    }

    .image-item {
      height: 100%;
      overflow: hidden;
    }

    .image-item-buttons {
      position: absolute;
      right: 10px;
      bottom: 10px;
      display: none;

      &.cropping {
        display: block;
      }
    }

    &:hover {
      .image-item-buttons {
        display: block;
      }
    }
  }

  .title-row {
    display: flex;
  }

  .title-image {
    display: inline-block;
    flex-grow: 1;
    padding: 1rem;

    svg {
      width: 188px;
    }
  }

  .host-login {
    display: inline-block;
    padding: 1rem 1.5rem;
    color: rgba(0, 0, 0, 0.5);
    font-size: 1rem;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 300;

    .login-emphasis {
      margin-left: 3px;
      font-weight: 500;
    }
  }

  .flex-container {
    display: flex;
    align-items: center;
  }

  .title {
    font-weight: 700;
    margin-bottom: 12px;
  }

  .subtitle {
    color: #888;
    font-weight: 300;
    margin-bottom: 40px;
  }

  .field.is-grouped {
    margin-bottom: 100px;
  }

  .room-population {
    color: $sbcarribean;
    font-weight: 500;
    font-size: 15px;
  }

  .room-name {
    width: 170px;
    padding-right: 8px;
  }

  @include mobile {
    .join-room-button {
      font-size: 1rem;
    }

    .room-name {
      font-size: 1.5rem;
    }
  }

  .image-item-buttons {
    button {
      margin-right: 5px;
    }
  }

  .locked-icon {
    margin-right: 0.75rem !important;
  }
}

.foyer > svg {
  width: 100%;
  max-width: 175px;
  text-align: center;
  display: block;
  margin-left: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 768px) {
  .start-button {
    font-size: 16px;
    padding: 13px 20px;
  }

  .name-fields {
    .input {
      font-size: 1rem;
    }

    .button {
      font-size: 1rem;
    }
  }
}

.create-new-room-box {
  box-shadow: none;
  border-width: 1px;
  border-color: $blue;
  border-style: solid;
  background-color: transparent;
  padding: 18px;
  color: $blue;
  display: flex;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background-color: white;
    cursor: pointer;
  }

  .icon {
    display: block;
    margin-right: 10px;
    width: 16px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .create-new-room-text {
    text-align: center;
    font-size: 17px;
  }
}

.upload-instructions {
  .modal-card-head {
    background-color: white;
  }

  .modal-card-foot {
    justify-content: flex-end;
    background-color: white;
    border-top: none;
  }

  p.instructions-line {
    margin-bottom: 0.5rem;
  }

  dl {
    display: flex;
    flex-flow: row wrap;
    border: solid #333;
    border-width: 1px 1px 0 0;
  }

  dt {
    flex-basis: 20%;
    padding: 2px 8px;
    background: #333;
    text-align: right;
    color: #fff;
  }

  dd {
    flex-basis: 70%;
    flex-grow: 1;
    margin: 0;
    padding: 2px 6px;
    border-bottom: 1px solid #333;
  }
}

.office-foyer {
  display: flex !important;
  justify-content: center;

  .centered-office-foyer {
    max-width: 900px;
    width: 100%;

    .columns {
      &.without-side-menu {
        @media only screen and (max-width: 768px) {
          display: flex;
          .column {
            width: clamp(210px, 31vw, 307.5px);
            flex: none;
          }
        }

        @media only screen and (max-width: 665px) {
          .column {
            width: clamp(210px, 31vw, 307.5px);
          }
        }

        @media only screen and (min-width: 669px) and (max-width: 770px) {
          .office-foyer__card__avatar {
            width: 33px;
            height: 33px;
          }

          .office-foyer__card__avatar__initials {
            font-size: 1.2em;
          }
        }
      }

      &.with-side-menu {
        @media only screen and (min-width: 769px) and (max-width: 976px) {
          .column {
            width: 50%;
          }
        }

        @media only screen and (max-width: 768px) {
          .column {
            width: clamp(210px, 40vw, 307.5px);
          }
        }

        @media only screen and (min-width: 977px) and (max-width: 1130px),
          (max-width: 608px),
          (min-width: 769px) and (max-width: 840px) {
          .office-foyer__card__avatar {
            width: 33px;
            height: 33px;
          }

          .office-foyer__card__avatar__initials {
            font-size: 1.2em;
          }
        }
      }
    }
  }

  .foyer-actions {
    display: flex;
    margin-bottom: auto;
    margin-top: auto;

    .button {
      display: flex;
      background: #f9f9f9;
      padding: 0.3em 0.9em;
      height: 34px !important;

      &:focus,
      &:active,
      &.is-focused {
        box-shadow: none;
      }

      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }

    .link-button {
      color: #767676;
      margin-right: 0.5em;

      svg {
        transform: rotate(44deg);
      }
    }

    @media screen and (max-width: 768px) {
      .button {
        height: 24px !important;
      }

      button {
        width: 24px;
        font-size: 0.9rem;
        .subscribed-bell {
          font-size: 0.9rem;
        }
      }

      .top-members {
        font-size: 0.8rem;
        .image {
          height: 16px;
          width: 16px;
        }
      }

      .member-count {
        margin-bottom: -2px !important;
        font-size: 0.8rem;
      }
    }
  }

  .subscribed-bell {
    vertical-align: middle;
    color: #767676;
    padding: 0.3em;
    font-size: 1rem;

    &.unsubscribed {
      color: #b70f19;
    }
  }

  h1.title {
    font-style: normal;
    font-weight: bold;
    line-height: 1;
    color: #222222;
    margin-bottom: 0;
    margin-top: 30px;

    display: block;
    align-items: center;
    flex-direction: row;
  }

  div.subtitle {
    margin-top: 0.75rem;
    font-style: normal;
    font-weight: 300;
    line-height: 12px;
    color: #222222;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem !important;

    .floating-actions {
      font-size: 1rem;
      align-self: center;
    }

    .editable-name,
    .description-placeholder {
      margin-bottom: -2px;
    }
  }

  .box {
    padding: 1rem;
  }

  .add-room-button-row {
    margin-top: 4.5rem;
    margin-bottom: 1.5rem;

    .add-room-button {
      margin-right: 0.75em;
    }

    .level-right {
      margin-top: 0;
    }

    .level-item {
      justify-content: start;
    }
  }

  .space-icon {
    font-size: 0.85em;
    margin-right: 0.2em;
    margin-top: 0.1em;
  }

  .office-foyer-title__wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;

    .floating-actions {
      font-size: 1.4rem;
      align-self: center;
    }

    .space-title-container {
      display: flex;
    }
  }

  @media only screen and (max-width: 768px) {
    h1.title {
      margin-bottom: 10px;
      min-width: 200px;
    }

    .office-foyer-title__wrapper {
      display: block;
      margin-top: -9px;
      margin-left: -2px;
    }

    .add-room-button-row {
      margin-top: 2.5rem;
    }
  }

  .office-foyer-box {
    box-shadow: $foyer-box-shadow;
    border-radius: 8px;
  }

  .office-foyer-box:not(.room-locked):hover {
    cursor: pointer;
    box-shadow: $foyer-box-shadow-hover;
  }

  .editable-name {
    display: inline;
    outline: none;
    cursor: text;

    &:focus {
      background-color: lemonchiffon;
      border-radius: 5px;
    }

    &:hover:not(:focus) {
      background-color: #fbfbfb;
    }
  }

  .hover-for-actions {
    display: flex;
    align-items: center;
  }

  .hover-for-actions:hover {
    .floating-actions {
      visibility: visible;
    }
  }
  .floating-actions {
    background-color: #f5f5f5;
    display: inline-block;
    visibility: hidden;

    .room-action-loader {
      @extend %loader;
      margin-left: 5px;
      font-size: 13px;
      display: inline-block;
      visibility: hidden;

      &.active {
        visibility: visible;
      }
    }
  }

  .pen-icon {
    font-size: 0.75em;
    display: inline-block;
    cursor: pointer;
    margin-left: 0.4em;

    svg {
      color: #a7a7a7;
      vertical-align: initial;

      &:hover {
        color: $blue;
      }
    }
  }

  .office-foyer-header {
    position: relative;
    margin-left: 100px;
    display: flex;

    .description-placeholder {
      display: inline-block;
      color: grey;
      margin-right: -1ex;
    }
    .editable-name {
      display: inline-block;
      min-width: 1ex;
      line-height: 1em;
    }

    &__space-image__wrapper {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: #e6e6e6;
      position: absolute;
      right: calc(100% + 22px);
      top: 20px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        svg {
          display: block;
          opacity: 0.7;
        }
      }

      div.logo-wrapper {
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &.with-logo {
          svg {
            display: none;
            position: absolute;
            color: white;
          }

          &:hover {
            svg {
              display: block;
            }
          }
        }

        &:hover {
          opacity: 0.7;
          svg {
            opacity: 1;
          }
        }

        svg {
          left: calc(50% - 14px);
          top: calc(50% - 14px);
          color: grey;
          width: 28px;
          height: 28px;
          padding: 4px;
        }
      }

      .upload-logo-wrapper {
        display: flex;
      }

      svg {
        height: 24px;
        width: 24px;
      }

      .tippy-box {
        min-width: 200px;
      }
    }
  }

  .space-membership-population {
    display: flex;
    align-items: center;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    line-height: 23px;
    vertical-align: middle;
    height: 100px !important;

    .member-count {
      display: flex;
      align-items: center;
      margin-bottom: -3px;
    }
  }

  .top-members {
    display: flex;
    margin-right: 0.5rem;

    figure:not(:last-child) {
      display: inline-block;
      margin-right: -11px;
    }
    img {
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
        rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    }
  }
}

.foyer-more-popup {
  width: 250px;
}

.meeting-foyer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5vh;
    margin-bottom: 1rem;
  }

  .hover-for-actions {
    display: inline;
    cursor: text;

    &:hover {
      .pen-icon:not(.is-saving) {
        display: block !important;
      }
    }
  }

  .floating-actions {
    width: 0.9em;
    vertical-align: text-top;

    .pen-icon {
      display: none;
    }
    .room-action-loader {
      font-size: 0.5em;
      display: none;
      margin-top: 0.6rem;

      &.active {
        display: block;
      }
    }
  }

  .pen-icon {
    font-size: 0.5em;
    vertical-align: middle;
  }

  h1.title.is-3 {
    line-height: 2.4rem;

    .link-button-wrapper {
      vertical-align: baseline;
    }

    .link-button {
      width: 1.5rem;
      padding: 0.3em 1.1rem;
      height: 2.2rem !important;
      color: #767676;

      svg {
        transform: rotate(44deg);
      }
    }

    .meeting-settings-button {
      margin-left: 0.333rem;
    }
  }

  .meeting-foyer-content {
    width: 100%;

    .single-join-container {
      margin-top: 2.5em;
      margin-bottom: 2.5rem;
      display: flex;
      justify-content: center;
    }

    .join-room-button {
      padding-left: calc(1em + 0.5em);
      padding-right: calc(1em + 0.5em);
      height: 2.25em;
    }
  }
}
