.reapop__notification-title {
  display: flex;
  justify-content: space-between;

  .notification-time-stamp {
    font-size: 11px;
    font-weight: 600;
    padding-top: 3px;
  }
}
