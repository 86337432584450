@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "../../styles/colors.scss";

.tile-container {
  height: 100%;
  position: static;
  flex-grow: 1;
}

.tile.small {
  width: 200px;
  height: 100%;
  margin: 0 6px;
  position: relative;
  flex-basis: auto;
  flex-grow: 0;
}

.tile.large {
  position: relative;
  /* margin: 2px; */
  /* max-height: calc(100vh - 208px); */
  height: 100%;
  /* background-color: red; */
  overflow: hidden;

  .video-item {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  &.resizing {
    .video-item {
      position: absolute;
      width: 50%;
      height: auto;
      min-width: 150px;
      min-height: 150px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
    }

    .video-item:before {
      content: "";
      float: left;
      padding-top: 100%; /* initial ratio of 1:1*/
    }
  }
}

.tile.large:not(.screenshare) video {
  /* Delete so that vertical video fills the frame */
  height: 100%;
  object-fit: cover;
}

.tile.screenshare {
  border-bottom: 1px solid #111;

  .background {
    background-color: #111;
  }
}

.tile.screenshare video {
  height: 100% !important;
  width: 100% !important;
  object-fit: contain;
}

.tile video {
  // width: 100%;
  width: auto;
  position: absolute;
  top: 0px;
  border-radius: 10px;
}

.tile .background {
  // background-color: #333;
  background-color: #111;
  width: 100%;
  height: 100%;
  padding-top: 56.25%; /* Hard-coded 16:9 aspect ratio */
  // background-color: rgba(64, 64, 64, 0.5);
  border-radius: 10px;
}

.tile.local:not(.screenshare) video {
  transform: scale(-1, 1);
}

.tile.small video {
  border-radius: 4px;
  height: 100%;
}

.tile.small .background {
  border-radius: 4px;
  background-color: #333 !important;
}

.tile .loading {
  @extend %loader;
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  font-size: 16px;
  line-height: 17px;
}

.tile .camera-blocked-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  font-size: 32px;
  line-height: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tile .cam-muted {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__avatar,
  &__initials {
    min-height: 75px;
    min-width: 75px;
    max-height: 150px;
    max-width: 150px;
    height: 12vh;
    width: 12vh;
    border-radius: 50%;
    border: 2px solid #fff;
    // margin-bottom: 1rem;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    font-size: 7vh;
    font-weight: bold;
    color: #fff;
  }

  .video-mute-icon {
    margin-top: 1.5rem;
  }
}

.tile.small .cam-muted {
  &__avatar,
  &__initials {
    min-height: 28px;
    min-width: 28px;
    max-height: 56px;
    max-width: 56px;
    font-size: 2.25rem;
  }

  .video-mute-indicator {
    font-size: 1rem;
    padding-bottom: 1.25rem;

    .video-mute-icon {
      display: none;
    }

    svg:not(:first-child) {
      margin-left: 0.5rem;
    }
  }
}

.tile .tile-name-tag {
  text-align: center;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: 7px;
  width: 100%;
  font-weight: 500;
  cursor: default;
  min-height: 40px;
  align-items: center;
  justify-content: center;

  .tile-bottom-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.tile:hover .tile-name-tag {
  color: white;
}

.tile.active-speaker {
  .controls-overlay {
    border-radius: 7px;
    box-shadow: 0px 0px 0px 3px #00d182 inset;
  }
}

.tile-name-button {
  padding: 7px 8px;
}

.controls-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .mute-icon {
    font-size: 18px;
    font-weight: 900;
    color: white;
    z-index: 1;
    align-self: flex-start;
    margin-top: 5px;
  }

  .video-mute-indicator {
    font-size: 2rem;
    svg:not(:first-child) {
      margin-left: 1rem;
    }
  }

  .mic-mute-indicator {
    font-size: 23px;
    position: absolute;
    left: 20px;
    top: 15px;
    background-color: #fb5554;
    color: #fff;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.tile {
  .name-component-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .name-component-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .name-component-exit {
    opacity: 1;
  }
  .name-component-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  &.small {
    .tile-name-tag {
      bottom: 0;
      .button {
        font-size: 12px;
        padding: 7px;
        .tile-name-tag-content {
          span {
            padding-bottom: 1px;
            padding-top: 1px;
            max-width: 115px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .mic-mute-indicator {
      top: 17px;
      left: 8px;
      font-size: 12px;
      height: 24px;
      width: 24px;
    }

    .pull-aside__container {
      .button {
        padding: 0;
        height: 22px;
        width: 22px;
        .icon {
          font-size: 14px;
        }
      }
    }

    &.active-speaker {
      .mic-mute-indicator {
        top: 10px;
        left: 7px;
      }
    }
  }

  .tile-participant-actions-container {
    display: none;
    height: 0;
  }

  &:hover {
    .tile-participant-actions-container {
      display: flex;
    }

    .tile-participant-actions-overlay {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 99;
      background-color: rgba(34, 34, 34, 0.7);
    }

    .tile-name-tag-content,
    .pull-aside__container button,
    .tile-settings-container button {
      background-color: rgba(255, 255, 255, 0.7);
      color: #000;

      &:hover {
        background-color: rgba(255, 255, 255, 0.9);
        color: #000;
      }
    }
  }

  .tile-name-tag-content {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 0 8px;
    border-radius: 4px;
    color: #fff;

    span {
      padding-bottom: 2px;
      padding-top: 2px;
      margin-right: 5px;
    }

    div {
      display: flex;
    }
  }

  .tile-debug-info {
    font-size: 80%;
    position: absolute;
    bottom: 40px;
    color: white;
    background-color: rgba(0, 0, 0, 0.75);
    padding: 4px 8px;
  }

  .pull-aside__container,
  .tile-settings-container {
    display: inline-block;

    .button {
      background-color: rgba(0, 0, 0, 0.4);
      color: #fff;
      // background-color: rgba(255, 255, 255, 0.7);
      border: none;
      border-radius: 30px;
      height: 28px;
      width: 28px;
      padding: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        font-size: 16px;
        height: 16px;
        width: 16px;
      }

      &:hover {
        background-color: white;
      }
    }
  }

  // &.my-node-appear {
  //   transform: translateX(-500px);
  //   opacity: 0;
  //   position: absolute;
  // }

  // &.my-node-enter.my-node-appear-active {
  //   transform: translateX(0);
  //   opacity: 1;
  //   transition: all 1000ms linear 1000ms;
  // }

  // &.my-node-appear-done {
  //   opacity: 1;
  // }

  &.my-node-enter {
    opacity: 0;
    transform: translateX(-20vw);
  }
  &.my-node-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease;
  }
  &.my-node-enter-done {
    opacity: 1;
    transform: translateX(0);
  }

  &.my-node-exit {
    opacity: 1;
    transform: translateX(0);
  }
  &.my-node-exit-active {
    opacity: 0;
    transform: translateX(20vw);
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease;
  }
  &.my-node-exit-done {
    opacity: 0;
  }
}

.no-side-menu {
  .large-tiles > div > div > div:first-child {
    .pull-aside__container {
    }
  }
}

.js-object-pre {
  white-space: pre;
  text-align: left;
  font-size: xx-small;
}

#my-reactions,
#other-users-reactions {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  font-size: clamp(90px, 10vh, 150px);
}

.small-tiles {
  #my-reactions,
  #other-users-reactions {
    font-size: 50px;
    top: 50%;
  }
}
