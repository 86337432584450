#local-video {
  --spacing: 100%;

  width: var(--spacing) !important;
}

#local-tile-background {
  --base-color: #222222;

  background-color: var(--base-color) !important;
}
