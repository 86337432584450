.confirm-modal-message {
  text-align: center;
}

.confirm-modal-buttons {
  margin-top: 20px;
  text-align: center;

  & > button {
    margin: 0 10px;
  }

  button:not(:last-child) {
    margin-right: 0;
  }
}
