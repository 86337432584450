@import "../../styles/colors";

.reactions-items-row {
  display: flex;
}

.reactions-item {
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  border-radius: 3px;
}

.reactions-item:hover {
  background-color: rgb(243, 243, 243) !important;
}
