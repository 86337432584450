.modal-not-verified {
  padding: 10px;

  &__title {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 15px;
  }

  &__subtitle {
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__content {
    p:not(:first-child) {
      margin-top: 0.8em;
    }
  }

  &__resend {
    font-size: 14px;
    margin-top: 10px;
    opacity: 0.8;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    button {
      margin-right: 15px;
      border: none;
      box-shadow: none !important;

      &:disabled {
        color: #fff !important;
        background-color: #ccc !important;
      }
    }
  }

  &__confirm {
    background-color: rgba(1, 177, 151, 1);
    color: #fff !important;

    &:hover {
      color: #fff;
      background-color: rgba(1, 177, 151, 0.95);
    }

    &:active {
      color: #fff;
    }
  }

  &__cancel {
    background-color: rgba(1, 177, 151, 0.15);
    color: #01b197 !important;

    &:hover {
      color: #01b197;
      background-color: rgba(1, 177, 151, 0.25);
    }
  }

  .user-email {
    opacity: 0.8;
    display: inline;
  }
}
