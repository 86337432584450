.subscription-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 1024px;

  &>div:first-child {
    width: 60%;
  }

  &>div:nth-child(2) {
    width: 37%;
  }
}

.subscription-wrap {
  .title {
    text-align: center;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .subscription-title {
    font-size: 2.5rem !important;
    margin-bottom: 15px !important;
  }

  .subtitle {
    text-align: center;
    color: #6f7782;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 2.5rem;
  }
}