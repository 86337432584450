@import "../../styles/colors.scss";

#office-container {
  background-color: white;
  display: grid;
  grid-template-columns: 22rem auto;
  grid-template-rows: 1fr;
  min-height: 100%;
  height: 100vh;
  width: 100vw;
  /* https://www.flickr.com/photos/winmenot/23600473843/ */
  // background-image: url("/assets/images/mountains.jpg");
  // background-size: cover;

  &.no-side-menu {
    grid-template-columns: 1fr;

    .office-side-menu {
      display: none;
    }

    .install-promo-banner {
      // margin-left: 3.5rem;
    }

    .primary-content {
      width: 100vw;
    }
  }

  .primary-content {
    width: calc(100vw - 22rem);
  }

  .title.is-1 {
    font-weight: 600;
    font-size: 2rem;
  }

  .foyer-body-padding {
    padding: 2rem;
    padding-top: 1rem;
    overflow-y: scroll;
    background-color: #f4f4f4;
    height: 100%;
  }

  .office-welcome {
    width: 100%;
    height: 100%;
    /* https://www.flickr.com/photos/winmenot/23600473843/ */
    background-image: url("/assets/images/mountains.jpg");
    background-size: cover;
  }

  .call {
    background-color: #111111;
    display: flex;

    .doc-pane {
      width: 20%;
      min-width: 450px;
      background-color: #111111;
    }
  }

  .office-welcome {
    .install-promo-banner {
      display: flex;
      padding: 0.75rem 1rem;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // background-color: #ffce44;
      background-color: $sbroyalblue;
      height: 44px;
      color: white;

      strong {
        color: white;
      }
    }
  }
}

.office-side-menu {
  // background: linear-gradient(
  //   0deg,
  //   rgba(255, 255, 255, 0.6) 0%,
  //   rgba(255, 255, 255, 0.9) 50%,
  //   rgba(255, 255, 255, 1) 100%
  // );

  .padding {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.space-members-modal {
  h4 {
    margin-left: 0.5rem;
  }
}

.install-unsupported-modal {
  padding: 1.5rem;

  .learn-more-link {
    svg {
      margin-left: 0.25rem;
    }
  }
}

.doc-settings-modal {
  min-width: 30rem;
}
