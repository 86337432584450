.coupon-container {
  padding: 0 24px;

  .valid-coupon {
    margin-top: 8px;
    color: #00D1B2;
  }

  .invalid-coupon {
    color: red;
    font-size: 12px;
  }

  .discount-code-text {
    cursor: pointer;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .coupon-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .subscription-card__actions {
      margin: 0;
    }

    .form-component {
      margin: 0;
      width: 66%;
    }
  }
}