.app {
  background-color: #111;
  position: absolute;
  height: 100%;
  width: 100%;
  &.white-app-bg {
    background-color: whitesmoke;
  }
}

.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 100ms ease-in-out;
  z-index: 2;
  background: rgba(10, 10, 10, 0.86);
  &--after-open {
    opacity: 1;
  }
  &--before-close {
    opacity: 0;
  }
}

.modal-background-clear {
  background: rgba(0, 0, 0, 0) !important;
}

.modal-action-row {
  margin-top: 15px;
}

.animate-ring-svg {
  svg {
    animation: ring 2s ease-in-out infinite;
  }
}

.animate-ring {
  animation: ring 2s ease-in-out infinite;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(26deg);
  }
  10% {
    transform: rotate(-24deg);
  }
  15% {
    transform: rotate(30deg);
  }
  20% {
    transform: rotate(-28deg);
  }
  25% {
    transform: rotate(26deg);
  }
  30% {
    transform: rotate(-22deg);
  }
  35% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-16deg);
  }
  45% {
    transform: rotate(12deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  55% {
    transform: rotate(8deg);
  }
  60% {
    transform: rotate(-6deg);
  }
  65% {
    transform: rotate(2deg);
  }
  70% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(0deg);
  }
  95% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
