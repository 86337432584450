.install-content {
  padding: 2rem;
  padding-bottom: 2.5rem;
  text-align: center;
  img {
    width: 96px;
  }

  h1 {
    font-weight: 400;
    margin-top: 0.5rem !important;
    margin-bottom: 3rem;
    font-size: 2.25rem;
  }

  .button.is-large {
    font-size: 1.25rem;
  }
}
