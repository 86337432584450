.stripe-billing-info {

  &__two-cols {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &>div {
      width: 48%;
    }
  }
}