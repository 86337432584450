.billing-card {
  padding: 24px 36px;
  background: #fff;
  transition-duration: 200ms;
  transition-property: background, border-color, box-shadow;
  margin-bottom: 30px;

  &__title {
    margin-bottom: 22px;
    line-height: 20px;
    font-weight: 600;
    font-size: 24px;
    color: #273240;
    text-align: left !important;
  }

  .billing-card-container {
    &__subtitle {
      color: #858585;
      font-weight: 400;
    }
  }

  .billing-card-table {
    &__row {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 10px;
      font-weight: 500;
      color: #252525;

      div {
        flex: 1;
      }
    }

    &__cap {
      color: #757b86;
      font-weight: 600;
      margin-top: -10px;
      margin-bottom: 20px;
    }

    .current-subscription {
      display: inline-block;
      padding: 1px 12px;
      color: #fff;
      border-radius: 8px;
      background-color: #00d1b2;
      margin-left: 10px;
    }

    &.subscription-actions {
      .button-cancel {
        background-color: #dfdfdf !important;
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
      }

      .button-reactivate {
        background-color: #00d1b2 !important;
        color: #fff !important;
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
  }

  .link-button {
    line-height: 24px;
    font-size: 16px;
    cursor: pointer;
    color: #14aaf5;
    fill: #14aaf5;
    text-align: right;
    max-width: 150px;
  }
}
